import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";

export default class PlayScene extends Scene {
  constructor() {
    super({ key: "PlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
  }

  avatarObject = [];
  friendAvatarObject = [];
  fallingFruitsGroup;
  isGameOngoing = true;
  isCollisionEnabled = true;
  isProtectionOn = false;
  canMove = false;
  chats = [];
  currentChatId = 0;
  ifFirstTut = false;
  ifSecondTut = false;
  ifThirdTut = false;
  ifForthTut = false;
  totalCountForStop = 0;
  redFruitCount = 0;

  create() {
    this.chats_1 = [
      i18n.t("miniGames.foraging.tutorial.dialog_4"),
      i18n.t("miniGames.foraging.tutorial.dialog_5"),
      i18n.t("miniGames.foraging.tutorial.dialog_6"),
    ];
    this.chats_2 = [
      i18n.t("miniGames.foraging.tutorial.dialog_7"),
      i18n.t("miniGames.foraging.tutorial.dialog_8"),
    ];
    this.chats_3 = [
      i18n.t("miniGames.foraging.tutorial.dialog_9"),
      i18n.t("miniGames.foraging.tutorial.dialog_10"),
    ];
    this.chats_4 = [i18n.t("miniGames.foraging.tutorial.dialog_14")];

    // Background
    this.backgroundImage = this.add
      .image(0, 0, "backgroundImage")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this.scoreBoard = this.add.image(80, 35, "score").setScale(0.3).setDepth(2);
    this.resultBoard = this.add
      .image(70, 70, "score")
      .setScale(0.25, 0.2)
      .setDepth(2);
    this.settingBtn = this.add
      .image(440, 40, "setting")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.feedback = this.add
      .text(240, 390, "", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 48,
      })
      .setOrigin(0.5)
      .setScale(0)
      .setDepth(5)
      .setShadow(3, 3, "#000", 0, true, true);

    // NPC and Drum
    this.drumHitSound = this.sound.add("drumHitSound");
    this.drumHitSound.loop = true;
    this.npc = this.add.image(60, 630, "npc").setScale(0.5);
    this.drum = this.add.image(130, 560, "drum").setScale(0.4);
    this.spriteDrumHammer = this.add
      .sprite(90, 580, "spriteDrumHammer")
      .setScale(0.5);
    this.anims.create({
      key: "DrumingUseHammer",
      frames: this.anims.generateFrameNumbers("spriteDrumHammer", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
      repeat: -1,
    });

    // Animations
    this.spriteHit = this.add
      .sprite(0, 535, "spriteHit")
      .setScale(0.5)
      .setAlpha(0);
    this.anims.create({
      key: "spikeBallHitting",
      frames: this.anims.generateFrameNumbers("spriteHit", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.spriteSplash = this.add
      .sprite(0, 535, "spriteSplash")
      .setScale(0.5)
      .setAlpha(0);
    this.anims.create({
      key: "splashing",
      frames: this.anims.generateFrameNumbers("spriteSplash", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.spriteCatch = this.add
      .sprite(0, 535, "spriteCatch")
      .setScale(0.5)
      .setAlpha(0);
    this.anims.create({
      key: "catching",
      frames: this.anims.generateFrameNumbers("spriteCatch", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.spriteFruitShine = this.add
      .sprite(0, 0, "spriteFruitShine")
      .setAlpha(0);
    this.anims.create({
      key: "FruitShine",
      frames: this.anims.generateFrameNumbers("spriteFruitShine", {
        frames: [0, 1, 2, 3],
      }),
      repeat: -1,
      frameRate: 5,
    });

    this.anims.create({
      key: "SnakeDance",
      frames: this.anims.generateFrameNumbers("spriteSnake", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });

    this.spriteDizzy = this.add
      .sprite(0, 550, "spriteDizzy")
      .setScale(1)
      .setAlpha(0)
      .setDepth(3);
    this.anims.create({
      key: "avatarDizzy",
      frames: this.anims.generateFrameNumbers("spriteDizzy", {
        frames: [0, 1, 0, 1, 0, 1, 2],
      }),
      frameRate: 5,
    });

    this.spriteQuestion = this.add
      .sprite(0, 0, "spriteQuestion")
      .setScale(0.6)
      .setAlpha(0)
      .setDepth(7)
      .setInteractive({ cursor: "pointer" })
      .on("pointerdown", this.goQuestion, this);
    this.anims.create({
      key: "questionTime",
      frames: this.anims.generateFrameNumbers("spriteQuestion", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });

    this.spriteEnergy = this.add
      .sprite(0, 0, "spriteEnergy")
      .setScale(0.7)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarEnergy",
      frames: this.anims.generateFrameNumbers("spriteEnergy", {
        frames: [2, 3, 4, 5],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.spritePower = this.add
      .sprite(0, 0, "spritePower")
      .setScale(0.85)
      .setAlpha(0)
      .setDepth(7);
    this.anims.create({
      key: "avatarPower",
      frames: this.anims.generateFrameNumbers("spritePower", {
        frames: [2, 3, 4],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.anims.create({
      key: "smoking",
      frames: this.anims.generateFrameNumbers("smoke_sprite", {
        frames: [3, 4, 5, 4, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.anims.create({
      key: "rottenDestroy",
      frames: this.anims.generateFrameNumbers("spriteRottenDestory", {
        frames: [1, 2, 3, 4, 5],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "snakeDestroy",
      frames: this.anims.generateFrameNumbers("spriteSnakeDestory", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      }),
      frameRate: 10,
    });

    // Sounds
    this.scoreIncreaseSound = this.sound.add("scoreIncrease");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.powerHitSound = this.sound.add("powerHitSound");
    this.powerPopSound = this.sound.add("powerPopSound");
    this.goSound = this.sound.add("go");
    this.chargeSound = this.sound.add("chargeSound");

    // Player Avarar and Basket
    this.basket = this.physics.add
      .sprite(this.avatarX, 610, "basket")
      .setScale(0.4)
      .setDepth(2);
    this.basketTop = this.add
      .rectangle(0, 0, 75, 5, 0xff0000)
      .setAlpha(0)
      .setDepth(2);
    this.physics.world.enable(this.basketTop);
    this.basketTop.body.setCollideWorldBounds(true);
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }
    this.energyLineX = new Phaser.Geom.Line(-50, 730, 50, 730);
    this.energyGraphicsX = this.add.graphics({
      lineStyle: { width: 10, color: 0x969696 },
    });
    this.energyGraphicsX.strokeLineShape(this.energyLineX).setDepth(7);
    this.energyGraphicsX.x = this.avatarObject[0].x;

    // Create the line

    this.energyLine = new Phaser.Geom.Line(-49, 730, -49, 730);
    this.energyGraphics = this.add.graphics({
      lineStyle: { width: 8, color: 0x00d4f0 },
    });
    this.energyGraphics.strokeLineShape(this.energyLine).setDepth(7);
    this.energyGraphics.x = this.avatarObject[0].x;

    this.tweens.add({
      targets: [this.basket],
      y: 600,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: 655,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    // Tutorial
    this.tutorialBackground = this.add.rectangle(240, 427, 480, 854, 0x000000);
    this.tutorialBackground.setInteractive().on("pointerdown", () => {
      if (!this.onShake) {
        this.onShake = true;
        this.tweens.add({
          targets: this.tutorialChat,
          x: this.tutorialChat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
          onComplete: () => {
            this.onShake = false;
          },
        });
        this.tweens.add({
          targets: this.tutorialChat.chat,
          x: this.tutorialChat.chat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.chat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.text,
          x: this.tutorialChat.text.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.text.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.button,
          x: this.tutorialChat.button.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.button.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.buttonText,
          x: this.tutorialChat.buttonText.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.buttonText.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
      }
    });
    this.tutorialChat = this.add.graphics();
    this.tutorialChat.lineStyle(2, 0x00ff00);
    this.tutorialChat.fillStyle(0xffffff, 0.8);
    this.tutorialChat.fillRoundedRect(10, 680, 460, 154, 20);

    this.anims.create({
      key: "click_anim",
      frames: this.anims.generateFrameNumbers("click_sprite", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
    this.tutorialClick = this.add
      .sprite(0, 0, "click_sprite")
      .setScale(0)
      .setDepth(10)
      .play("click_anim");

    const rect = new Phaser.Geom.Rectangle(10, 680, 460, 154);
    this.tutorialChat.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
    this.tutorialChat.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.chat = this.add
      .image(90, 755, "hamochi_talk")
      .setScale(0.25);

    this.tutorialChat.text = this.add.text(170, 740, "", {
      fontSize: "20px",
      wordWrap: { width: 300 },
      fontFamily: "Sassoon, sans-serif",
      color: "#000000",
      align: "left",
    });

    this.tutorialChat.text.setOrigin(0, 0.5);

    this.tutorialChat.button = this.add
      .graphics()
      .fillStyle(0xc7c7c7, 1)
      .fillRoundedRect(334, 802, 124, 20, 10);

    this.tutorialChat.buttonText = this.add.text(
      340,
      805,
      "▼" + i18n.t("action.clickToContinue"),
      {
        fontSize: "12px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      }
    );

    this.tutorialChat.buttonText.setInteractive({ useHandCursor: true });

    this.tutorialChat.buttonText.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialBackground.setDepth(9);
    this.tutorialChat.setDepth(9);
    this.tutorialChat.chat.setDepth(9);
    this.tutorialChat.text.setDepth(9);
    this.tutorialChat.button.setDepth(9);
    this.tutorialChat.buttonText.setDepth(9);

    this.tutorialBackground.setAlpha(0);
    this.tutorialChat.setAlpha(0);
    this.tutorialChat.chat.setAlpha(0);
    this.tutorialChat.text.setAlpha(0);
    this.tutorialChat.button.setAlpha(0);
    this.tutorialChat.buttonText.setAlpha(0);

    // User control
    this.leftArrowBtn = this.add
      .image(120, 775, "leftArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingLeft, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.rightArrowBtn = this.add
      .image(360, 775, "rightArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingRight, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.input.keyboard.on("keydown-LEFT", this.startMovingLeft, this);
    this.input.keyboard.on("keydown-RIGHT", this.startMovingRight, this);
    this.input.keyboard.on("keyup-LEFT", this.stopMoving, this);
    this.input.keyboard.on("keyup-RIGHT", this.stopMoving, this);

    this.fallingFruitsGroup = this.add.group();

    // Game transitions starts here
    this.feedback.setText(i18n.t("miniGames.general.ready"));
    this.feedback.setTint(0xffffff);
    if (this.round == 1) {
      this.scoreDetails = [];
      this.scoreDetails.push(
        this.add
          .rectangle(240, 427, 480, 854, 0x000000)
          .setAlpha(0.6)
          .setDepth(9)
      );

      this.scoreDetails.push(
        this.add.image(240, 400, "settingPanel").setScale(1, 1.5).setDepth(9)
      );

      this.scoreDetails.push(
        this.add.image(180, 220, "fruitGold").setScale(0.3).setDepth(9)
      );
      this.scoreDetails.push(
        this.add
          .text(250, 220, "+ 5", {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 24,
            color: "#492a12",
          })
          .setOrigin(0, 0.5)
          .setDepth(9)
      );

      this.scoreDetails.push(
        this.add.image(180, 275, "fruitRed").setScale(0.3).setDepth(9)
      );
      this.scoreDetails.push( 
        this.add
          .text(250, 275, "+ 2", {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 24,
            color: "#492a12",
          })
          .setOrigin(0, 0.5)
          .setDepth(9)
      );

      this.scoreDetails.push(
        this.add.image(180, 330, "fruitGreen").setScale(0.3).setDepth(9)
      );
      this.scoreDetails.push(
        this.add
          .text(250, 330, "+ 1", {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 24,
            color: "#492a12",
          })
          .setOrigin(0, 0.5)
          .setDepth(9)
      );

      this.scoreDetails.push(
        this.add.image(180, 385, "fruitRotten").setScale(0.3).setDepth(9)
      );
      this.scoreDetails.push(
        this.add
          .text(250, 385, "− 1", {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 24,
            color: "#492a12",
          })
          .setOrigin(0, 0.5)
          .setDepth(9)
      );

      this.scoreDetails.push(
        this.add.image(180, 440, "snake").setScale(0.25).setDepth(9)
      );
      this.scoreDetails.push(
        this.add
          .text(240, 440, i18n.t("miniGames.foraging.stun"), {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 24,
            color: "#492a12",
          })
          .setOrigin(0, 0.5)
          .setDepth(9)
      );

      this.scoreDetails.push(
        this.add.image(180, 495, "caterpilla").setScale(0.3).setDepth(9)
      );
      this.scoreDetails.push(
        this.add
          .text(240, 495, i18n.t("miniGames.foraging.stun"), {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 24,
            color: "#492a12",
          })
          .setOrigin(0, 0.5)
          .setDepth(9)
      );

      this.scoreDetails.push(
        this.add
          .image(240, 570, "okBtnImage")
          .setScale(0.6)
          .setDepth(9)
          .setInteractive({ useHandCursor: true })
          .on("pointerdown", this.clickOK, this)
      );

      this.scoreDetails.push(
        this.add
          .text(240, 570, i18n.t("miniGames.general.ok"), {
            fontSize: "24px",
            fill: "#000",
            fontFamily: "Sassoon, sans-serif",
          })
          .setDepth(9)
          .setOrigin(0.5)
      );

      this.tutorialClick.x = 320;
      this.tutorialClick.y = 590;
      this.time.delayedCall(500, () => {
        this.tutorialClick.setScale(0.5);
      });
    } else {
      this.currentChatId = 0;
      this.chats = this.chats_4;
      this.ifForthTut = true;
      this.tutorialChat.text.setText(this.chats[this.currentChatId]);
      this.startTutorial();
    }

    this.positiveHitSound = this.sound.add("positiveHitSound");
    this.rottenHitSound = this.sound.add("rottenHitSound");
    this.spikeHitSound = this.sound.add("spikeHitSound");
    this.btnPopSound = this.sound.add("btnPopSound");

    this.physics.add.collider(
      this.basketTop,
      this.fallingFruitsGroup,
      this.handleCollision,
      null,
      this
    );
  }

  clickOK() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scoreDetails.forEach((element) => {
      element.destroy();
    });
    this.tutorialClick.setScale(0);
    this.currentChatId = 0;
    this.chats = this.chats_1;
    this.ifFirstTut = true;
    this.tutorialChat.text.setText(this.chats[this.currentChatId]);
    this.startTutorial();
  }

  startTutorial() {
    this.tweens.add({
      targets: this.tutorialBackground,
      alpha: 0.7,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onStart: () => {
        if (this.tutorialClickBackground) {
          this.tweens.add({
            targets: this.tutorialClickBackground,
            alpha: 0,
            duration: 200,
            ease: "Sine.easeOut",
          });
        }
        if (this.lastBlackBg) {
          this.tweens.add({
            targets: this.lastBlackBg,
            alpha: 0,
            duration: 200,
            ease: "Sine.easeOut",
          });
        }
      },
      onComplete: () => {
        this.tweens.add({
          targets: this.tutorialChat,
          alpha: 1,
          duration: 100,
          ease: "Sine.easeOut",
          delay: 500,
          onComplete: () => {
            this.tweens.add({
              targets: this.tutorialChat.chat,
              alpha: 1,
              duration: 500,
              ease: "Sine.easeOut",
              onComplete: () => {
                this.tweens.add({
                  targets: [
                    this.tutorialChat.text,
                    this.tutorialChat.button,
                    this.tutorialChat.buttonText,
                  ],
                  alpha: 1,
                  duration: 500,
                  ease: "Sine.easeOut",
                });
              },
            });
          },
        });
      },
    });
  }

  continueChat() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.currentChatId++;
    if (this.chats[this.currentChatId]) {
      this.tweens.add({
        targets: this.tutorialChat.text,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.text.setText(this.chats[this.currentChatId]);
          this.tweens.add({
            targets: this.tutorialChat.text,
            alpha: 1,
            duration: 100,
            ease: "Sine.easeOut",
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [
          this.tutorialChat,
          this.tutorialChat.chat,
          this.tutorialChat.text,
          this.tutorialChat.button,
          this.tutorialChat.buttonText,
          this.tutorialBackground,
        ],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeOut",
      });
      if (this.ifFirstTut) {
        this.ifFirstTut = false;
        this.isRightClickTut = true;
        this.tutorialClickBackground = this.add
          .rectangle(240, 427, 480, 854, 0x000000)
          .setDepth(5)
          .setAlpha(0.7);
        this.rightArrowBtn.setDepth(6);
        this.rightArrowBtn.setInteractive({ useHandCursor: true });
        this.canMove = true;
        this.basket.setDepth(6);
        this.tutorialClick.x = 400;
        this.tutorialClick.y = 800;
        this.time.delayedCall(200, () => {
          this.tutorialClick.setScale(0.5);
        });
      }
      if (this.ifSecondTut) {
        this.ifSecondTut = false;
        this.tweens.add({
          targets: this.feedback,
          scaleX: 1.5,
          scaleY: 1.5,
          duration: 200,
          ease: "Sine.easeInOut",
          onComplete: () => {
            this.tweens.add({
              targets: this.feedback,
              scaleX: 0,
              scaleY: 0,
              duration: 100,
              ease: "Sine.easeInOut",
              delay: 1000,
              onComplete: () => {
                this.feedback.setText(i18n.t("miniGames.general.go"));
                this.feedback.setTint(0xffffff);
                this.tweens.add({
                  targets: this.feedback,
                  scaleX: 1.5,
                  scaleY: 1.5,
                  duration: 200,
                  ease: "Sine.easeInOut",
                  delay: 500,
                  onComplete: () => {
                    this.tweens.add({
                      targets: this.feedback,
                      scaleX: 0,
                      scaleY: 0,
                      duration: 100,
                      ease: "Sine.easeInOut",
                      delay: 1000,
                      onStart: () => {
                        this.goSound.setVolume(
                          store.state.settings.data.audio.sfx *
                            store.state.settings.data.audio.master
                        );
                        this.goSound.play();
                      },
                      onComplete: () => {
                        this.startGame();
                      },
                    });
                  },
                });
              },
            });
          },
        });
      }

      if (this.ifThirdTut) {
        this.lastBlackBg.setAlpha(0.7);
        this.spriteQuestion.setAlpha(1);
        this.spriteQuestion.play("questionTime");
        this.tutorialClick.x = this.spriteQuestion.x + 40;
        this.tutorialClick.y = this.spriteQuestion.y + 40;
        this.time.delayedCall(200, () => {
          this.tutorialClick.setScale(0.5);
        });
      }

      if (this.ifForthTut) {
        this.time.delayedCall(1000, () => {
          this.startGame();
        });
      }
    }
  }

  startGame() {
    this.spriteDrumHammer.play("DrumingUseHammer");

    this.drumHitSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.drumHitSound.play();

    this.leftArrowBtn.setInteractive({ useHandCursor: true });
    this.rightArrowBtn.setInteractive({ useHandCursor: true });
    this.canMove = true;
    this.spawnEngine();

    // Create the tween
    this.tween = this.tweens.add({
      targets: this.energyLine,
      x2: 49,
      duration: 17000,
      ease: "Sine.easeInOut",
    });
  }

  clickFriend() {
    this.tweens.add({
      targets: [this.bubble],
      y: this.avatarObject[0].y,
      x: this.avatarObject[0].x,
      duration: 500,
      ease: "linear",
      repeat: 0,
      onComplete: () => {
        this.powerPopSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.powerPopSound.play();
        this.bubble.destroy();
        this.spritePower.setAlpha(1);
        this.spritePower.play("avatarPower");
        this.isProtectionOn = true;
        this.tweens.add({
          targets: this.friendAvatarObject,
          y: "-=400",
          duration: 1000,
          ease: "linear",
          repeat: 0,
          onStart: () => {
            this.tweens.add({
              targets: [this.friendName, this.balloon],
              y: "-=400",
              duration: 1000,
              ease: "linear",
              repeat: 0,
            });
            if (this.bubble) {
              this.tweens.add({
                targets: [this.bubble],
                y: "-=400",
                duration: 1000,
                ease: "linear",
                repeat: 0,
              });
            }
          },
          onComplete: () => {
            for (var i = 0; i < this.friendAvatarObject.length; i++) {
              this.friendAvatarObject[i].destroy();
            }
            this.friendName.destroy();
            this.balloon.destroy();
            if (this.bubble) {
              this.bubble.destroy();
            }
          },
        });
      },
    });
  }

  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "PlayScene",
    });
  }

  goQuestion() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    let avatarX = this.avatarObject[0].x;
    this.avatarObject = [];
    this.friendAvatarObject = [];
    this.fallingFruitsGroup;
    this.totalCountForStop = 0;
    this.redFruitCount = 0;
    this.canMove = false;
    this.isGameOngoing = true;
    this.isCollisionEnabled = true;
    this.isProtectionOn = false;
    this.currentChatId = 0;
    this.ifFirstTut = false;
    this.ifSecondTut = false;
    this.ifThirdTut = false;
    this.ifForthTut = false;
    this.scene.start("QuestionScene", {
      avatar: this.avatar,
      round: this.round,
      score: this.score,
      questions: this.questions,
      friend: this.friend,
      bossRound: this.bossRound,
      noOfRounds: this.noOfRounds,
      difficulty: this.difficulty,
      countCorrect: this.countCorrect,
      avatarX: avatarX,
    });
  }

  startMovingLeft() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(-300);
      }
    }
  }

  startMovingRight() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(300);
      }
    }
  }

  stopMoving() {
    for (var i = 0; i < this.avatarObject.length; i++) {
      this.avatarObject[i].setVelocityX(0);
    }
  }

  handleCollision(x, fallingImage) {
    if (this.isCollisionEnabled) {
      fallingImage.destroy();

      if (fallingImage.score > 0) {
        this.score += fallingImage.score;
        this.scoreText.setText(
          i18n.t("miniGames.general.score") + " : " + this.score
        );
      } else if (!this.isProtectionOn && this.score > 0) {
        this.score += fallingImage.score;
        this.scoreText.setText(
          i18n.t("miniGames.general.score") + " : " + this.score
        );
      }

      let scoreHint = this.add
        .text(0, 0, "", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        })
        .setOrigin(0, 0.5)
        .setAlpha(0);
      scoreHint.setAlpha(1);

      if (fallingImage.score > 0) {
        scoreHint.setText("+" + fallingImage.score);
      } else if (fallingImage.score < 0 && !this.isProtectionOn) {
        scoreHint.setText(fallingImage.score);
      }
      scoreHint.x = this.basket.x;
      scoreHint.y = this.basket.y - 50;
      this.tweens.add({
        targets: [scoreHint],
        y: 500,
        duration: 500,
        ease: "Sine.easeOut",
        onStart: () => {
          if (
            fallingImage.type == "redFruit" ||
            fallingImage.type == "greenFruit"
          ) {
            this.totalCountForStop += fallingImage.score;
            this.energyGraphics.clear();
            this.energyLine.x2 = -49 + (99 * this.totalCountForStop) / 20;
            this.energyGraphics.strokeLineShape(this.energyLine);
            this.positiveHitSound.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.positiveHitSound.play();

            this.spriteCatch.setAlpha(1);
            this.spriteCatch.play("catching");

            if (this.avatarMask) {
              this.avatarMask.destroy();
            }
            this.avatarMask = this.add
              .image(this.avatarObject[0].x, this.avatarObject[0].y, "happy")
              .setScale(0.25)
              .setDepth(6);

            this.time.delayedCall(1000, () => {
              if (this.avatarMask && !this.avatarMask.spike) {
                this.avatarMask.setScale(0);
                this.avatarMask.destroy();
              }
            });
          } else if (this.isProtectionOn) {
            this.powerHitSound.setVolume(
              store.state.settings.data.audio.sfx *
                store.state.settings.data.audio.master
            );
            this.powerHitSound.play();
          }
          if (!this.isProtectionOn) {
            if (fallingImage.type == "rottenFruit") {
              this.rottenHitSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.rottenHitSound.play();

              this.spriteSplash.setAlpha(1);
              this.spriteSplash.play("splashing");

              if (this.avatarMask) {
                this.avatarMask.destroy();
              }
              this.avatarMask = this.add
                .image(this.avatarObject[0].x, this.avatarObject[0].y, "eek")
                .setScale(0.25)
                .setDepth(6);

              this.time.delayedCall(1000, () => {
                if (this.avatarMask && !this.avatarMask.spike) {
                  this.avatarMask.setScale(0);
                  this.avatarMask.destroy();
                }
              });
            }
            if (
              fallingImage.type == "caterpilla" ||
              fallingImage.type == "snake"
            ) {
              this.spikeHitSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.spikeHitSound.play();

              this.spriteHit.setAlpha(1);
              this.spriteHit.play("spikeBallHitting");
              this.spriteDizzy.setAlpha(1);
              this.spriteDizzy.play("avatarDizzy");

              if (this.avatarMask) {
                this.avatarMask.destroy();
              }
              this.avatarMask = this.add
                .image(this.avatarObject[0].x, this.avatarObject[0].y, "stun")
                .setScale(0.25)
                .setDepth(6);

              this.avatarMask.spike = true;
              this.stopMoving();
              this.canMove = false;
              for (var i = 0; i < this.avatarObject.length; i++) {
                this.avatarObject[i].setVelocityX(0);
              }
              this.isCollisionEnabled = false;

              this.time.delayedCall(1000, () => {
                if (this.avatarMask) {
                  this.avatarMask.setScale(0);
                  this.avatarMask.destroy();
                }
                this.leftArrowBtn.setInteractive({ useHandCursor: true });
                this.rightArrowBtn.setInteractive({ useHandCursor: true });
                this.canMove = true;
              });
              this.time.delayedCall(1200, () => {
                this.isCollisionEnabled = true;
              });
            }
          }
        },
        onComplete: () => {
          scoreHint.setAlpha(0);
        },
      });
    }
  }

  update() {
    if (this.isRightClickTut && this.avatarObject[0].x > 300) {
      this.isRightClickTut = false;
      this.isLeftClickTut = true;
      this.rightArrowBtn.setDepth(2);
      this.leftArrowBtn.setDepth(6);
      this.leftArrowBtn.setInteractive({ useHandCursor: true });
      this.canMove = true;
      this.tutorialClick.x = 200;
      this.tutorialClick.y = 800;
      this.time.delayedCall(200, () => {
        this.tutorialClick.setScale(0.5);
      });
    }

    if (this.isLeftClickTut && this.avatarObject[0].x < 200) {
      this.isLeftClickTut = false;
      this.leftArrowBtn.setDepth(2);
      this.stopMoving();
      this.canMove = false;
      this.tutorialClick.setScale(0);
      this.currentChatId = 0;
      this.chats = this.chats_2;
      this.ifSecondTut = true;
      this.basket.setDepth(2);
      this.tutorialChat.text.setText(this.chats[this.currentChatId]);
      this.startTutorial();
    }

    if (this.energyGraphics && this.energyLine && this.avatarObject[0]) {
      this.energyGraphicsX.x = this.avatarObject[0].x;
      this.energyGraphics.x = this.avatarObject[0].x;
    }

    if (this.avatarObject[0]) {
      this.basket.x = this.avatarObject[0].x;
      this.spriteEnergy.x = this.avatarObject[0].x;
      this.spriteEnergy.y = this.avatarObject[0].y - 10;
      this.spritePower.x = this.avatarObject[0].x + 2;
      this.spritePower.y = this.avatarObject[0].y - 27;
    }
    if (this.basket) {
      this.spriteHit.x = this.basket.x;
      this.spriteDizzy.x = this.basket.x;

      this.spriteSplash.x = this.basket.x;
      this.spriteCatch.x = this.basket.x;
      this.basketTop.x = this.basket.x;
      this.basketTop.y = this.basket.y - 40;
    }
    if (this.avatarMask && this.avatarObject[0]) {
      this.avatarMask.x = this.avatarObject[0].x;
      this.avatarMask.y = this.avatarObject[0].y;
    }

    if (this.totalCountForStop >= 20 && this.isGameOngoing) {
      this.isGameOngoing = false;
      this.time.removeEvent(this.spawnEvent);
      this.time.removeEvent(this.engine);
      this.gameFinish();
    }

    this.fallingFruitsGroup.getChildren().forEach(function (fallingImage) {
      if (this.totalCountForStop < 20) {
        if (fallingImage.y >= 700) {
          if (fallingImage.isActive) {
            fallingImage.isActive = false;
            if (
              fallingImage.type == "redFruit" ||
              fallingImage.type == "greenFruit"
            ) {
              this.totalCountForStop += fallingImage.score;
              this.energyGraphics.clear();
              this.energyLine.x2 = -49 + (99 * this.totalCountForStop) / 20;
              this.energyGraphics.strokeLineShape(this.energyLine);
              this.tweens.add({
                targets: fallingImage,
                y: "-=5",
                yoyo: true,
                duration: 500,
                ease: "Sine.easeInOut",
                onStart: () => {
                  this.tweens.add({
                    targets: fallingImage,
                    x: Math.random() < 0.5 ? "+=10" : "-=10",
                    duration: 500,
                    ease: "Sine.easeInOut",
                  });
                },
                onComplete: () => {
                  this.tweens.add({
                    targets: fallingImage,
                    alpha: 0,
                    duration: 500,
                    ease: "Sine.easeInOut",
                    onComplete: () => {
                      fallingImage.destroy();
                    },
                  });
                },
              });
            } else if (
              fallingImage.type == "caterpilla" ||
              fallingImage.type == "snake"
            ) {
              let xSprite = this.add
                .sprite(fallingImage.x, fallingImage.y - 20, "spriteSnakeDestory")
                .setScale(0.6)
                .setDepth(5)
                .play("snakeDestroy")
                .on("animationcomplete", () => {
                  xSprite.destroy();
                });
              fallingImage.destroy();
            } else {
              let xSprite = this.add
                .sprite(fallingImage.x, fallingImage.y - 10, "spriteRottenDestory")
                .setScale(1)
                .setDepth(5)
                .play("rottenDestroy")
                .on("animationcomplete", () => {
                  xSprite.destroy();
                });
              fallingImage.destroy();
            }
          }
        } else {
          if (fallingImage.type == "caterpilla") {
            fallingImage.y +=
              this.difficulty == 1 ? 2.5 : this.difficulty == 2 ? 3 : 3.5;
          } else if (fallingImage.type == "snake") {
            fallingImage.y +=
              this.difficulty == 1 ? 4 : this.difficulty == 2 ? 4.5 : 5;
          } else {
            fallingImage.y +=
              this.difficulty == 1 ? 3.5 : this.difficulty == 2 ? 4 : 4.5;
          }
        }
      } else {
        fallingImage.destroy();
      }
    }, this);
  }

  gameFinish() {
    this.chargeSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.chargeSound.play();

    if (this.friendName) {
      this.tweens.add({
        targets: this.friendAvatarObject,
        y: "-=400",
        duration: 1000,
        ease: "linear",
        repeat: 0,
        onStart: () => {
          this.tweens.add({
            targets: [this.friendName, this.balloon],
            y: "-=400",
            duration: 1000,
            ease: "linear",
            repeat: 0,
          });
          if (this.bubble) {
            this.tweens.add({
              targets: [this.bubble],
              y: "-=400",
              duration: 1000,
              ease: "linear",
              repeat: 0,
            });
          }
        },
        onComplete: () => {
          for (var i = 0; i < this.friendAvatarObject.length; i++) {
            this.friendAvatarObject[i].destroy();
          }
          this.friendName.destroy();
          this.balloon.destroy();
          if (this.bubble) {
            this.bubble.destroy();
          }
        },
      });
    }
    this.spriteDrumHammer.stop();
    this.spritePower.setAlpha(0);
    this.drumHitSound.stop();
    this.stopMoving();
    this.canMove = false;
    this.lastBlackBg = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(3);
    this.spriteEnergy.setAlpha(1);
    this.spriteEnergy.play("avatarEnergy");
    this.basket.visible = false;
    this.energyGraphics.visible = false;
    this.energyLine.visible = false;
    this.energyGraphicsX.visible = false;
    this.energyLineX.visible = false;

    if (this.avatarObject[0].x > 350) {
      this.spriteQuestion.x = this.avatarObject[0].x - 70;
    } else {
      this.spriteQuestion.x = this.avatarObject[0].x + 70;
    }
    this.spriteQuestion.y = this.avatarObject[0].y - 70;
    this.time.delayedCall(1000, () => {
      if (this.round == 1) {
        this.ifThirdTut = true;
        this.currentChatId = 0;
        this.chats = this.chats_3;
        this.tutorialChat.text.setText(this.chats[this.currentChatId]);
        this.startTutorial();
      } else {
        this.spriteQuestion.setAlpha(1);
        this.spriteQuestion.play("questionTime");
      }
    });
  }

  spawnEngine() {
    // * break the screen into 9 [whole width is 480]
    // * so the items will start fall from 40 to every 50
    // * 40, 90, 140, 190, 240, 290, 340, 390, 440

    var patterCount = 0;
    var dangerCount = this.difficulty == 1 ? 3 : this.difficulty == 2 ? 4 : 6;
    var rottenCount = this.difficulty == 1 ? 2 : this.difficulty == 2 ? 3 : 4;

    this.engine = this.time.addEvent({
      delay: 1300,
      callback: () => {
        patterCount++;
        if (dangerCount > 0 && rottenCount > 0 && patterCount % 2 == 0) {
          rottenCount--;
          dangerCount--;
          Math.random() < 0.5
            ? this.rotten_danger_pattern_1()
            : this.rotten_danger_pattern_2();
        } else if (dangerCount > 0 && patterCount % 2 == 0) {
          if (dangerCount > 1 && patterCount > 4) {
            dangerCount -= 2;
            Math.random() < 0.5
              ? this.danger_danger_pattern_1()
              : this.danger_danger_pattern_2();
          } else {
            dangerCount--;
            this.danger_pattern();
          }
        } else if (rottenCount > 0 && patterCount % 3 == 0) {
          rottenCount--;
          this.rotten_pattern();
        } else {
          const randomNum = Math.floor(Math.random() * 13) + 1;

          switch (randomNum) {
            case 1:
              this.fruits_pattern_2X1();
              break;
            case 2:
              this.fruits_pattern_2X2();
              break;
            case 3:
              this.fruits_pattern_2X3();
              break;
            case 4:
              this.fruits_pattern_3X1();
              break;
            case 5:
              this.fruits_pattern_3X2();
              break;
            case 6:
              this.fruits_pattern_3X3();
              break;
            case 7:
              this.fruits_pattern_3X4();
              break;
            case 8:
              this.fruits_pattern_3X5();
              break;
            case 9:
              this.fruits_pattern_4X1();
              break;
            case 10:
              this.fruits_pattern_4X2();
              break;
            case 11:
              this.fruits_pattern_4X3();
              break;
            case 12:
              this.fruits_pattern_4X4();
              break;
            case 13:
              this.fruits_pattern_4X5();
              break;
            default:
              this.fruits_pattern_2X1();
              break;
          }
        }
      },
      callbackScope: this,
      loop: true,
    });
  }

  fruits_pattern_2X1() {
    const randomNum = Math.floor(Math.random() * 6) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 500,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 500,
          callback: () => {
            this.spawnFruit(startingPosition + 50);
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_2X2() {
    const randomNum = Math.floor(Math.random() * 6) + 2;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 500,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 500,
          callback: () => {
            this.spawnFruit(startingPosition - 50);
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_2X3() {
    const randomNum = Math.floor(Math.random() * 7) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 500,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 500,
          callback: () => {
            this.spawnFruit(startingPosition);
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_3X1() {
    const randomNum = Math.floor(Math.random() * 5) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 400,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 400,
          callback: () => {
            this.spawnFruit(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 400,
              callback: () => {
                this.spawnFruit(startingPosition + 100);
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_3X2() {
    const randomNum = Math.floor(Math.random() * 5) + 3;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 400,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 400,
          callback: () => {
            this.spawnFruit(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 400,
              callback: () => {
                this.spawnFruit(startingPosition - 100);
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_3X3() {
    const randomNum = Math.floor(Math.random() * 7) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 400,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 400,
          callback: () => {
            this.spawnFruit(startingPosition);
            this.spawnEvent = this.time.addEvent({
              delay: 400,
              callback: () => {
                this.spawnFruit(startingPosition);
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_3X4() {
    const randomNum = Math.floor(Math.random() * 6) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 400,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 400,
          callback: () => {
            this.spawnFruit(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 400,
              callback: () => {
                this.spawnFruit(startingPosition);
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_3X5() {
    const randomNum = Math.floor(Math.random() * 6) + 2;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 400,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 400,
          callback: () => {
            this.spawnFruit(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 400,
              callback: () => {
                this.spawnFruit(startingPosition);
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_4X1() {
    const randomNum = Math.floor(Math.random() * 4) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 300,
          callback: () => {
            this.spawnFruit(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 300,
              callback: () => {
                this.spawnFruit(startingPosition + 100);
                this.spawnEvent = this.time.addEvent({
                  delay: 300,
                  callback: () => {
                    this.spawnFruit(startingPosition + 150);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_4X2() {
    const randomNum = Math.floor(Math.random() * 4) + 4;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 300,
          callback: () => {
            this.spawnFruit(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 300,
              callback: () => {
                this.spawnFruit(startingPosition - 100);
                this.spawnEvent = this.time.addEvent({
                  delay: 300,
                  callback: () => {
                    this.spawnFruit(startingPosition - 150);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_4X3() {
    const randomNum = Math.floor(Math.random() * 4) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 300,
          callback: () => {
            this.spawnFruit(startingPosition);
            this.spawnEvent = this.time.addEvent({
              delay: 300,
              callback: () => {
                this.spawnFruit(startingPosition);
                this.spawnEvent = this.time.addEvent({
                  delay: 300,
                  callback: () => {
                    this.spawnFruit(startingPosition);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_4X4() {
    const randomNum = Math.floor(Math.random() * 6) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 300,
          callback: () => {
            this.spawnFruit(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 300,
              callback: () => {
                this.spawnFruit(startingPosition);
                this.spawnEvent = this.time.addEvent({
                  delay: 300,
                  callback: () => {
                    this.spawnFruit(startingPosition + 50);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  fruits_pattern_4X5() {
    const randomNum = Math.floor(Math.random() * 6) + 2;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 300,
          callback: () => {
            this.spawnFruit(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 300,
              callback: () => {
                this.spawnFruit(startingPosition);
                this.spawnEvent = this.time.addEvent({
                  delay: 300,
                  callback: () => {
                    this.spawnFruit(startingPosition - 50);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  rotten_pattern() {
    const randomNum = Math.floor(Math.random() * 7) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 500,
      callback: () => {
        this.spawnRottenFruit(startingPosition);
      },
      callbackScope: this,
    });
  }

  danger_pattern() {
    const randomNum = Math.floor(Math.random() * 7) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 500,
      callback: () => {
        this.spawnDanger(startingPosition);
      },
      callbackScope: this,
    });
  }

  rotten_danger_pattern_1() {
    const randomNum = Math.floor(Math.random() * 6) + 2;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 400,
      callback: () => {
        this.spawnDanger(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 400,
          callback: () => {
            this.spawnFruit(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 400,
              callback: () => {
                this.spawnRottenFruit(startingPosition);
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  rotten_danger_pattern_2() {
    const randomNum = Math.floor(Math.random() * 5) + 1;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 400,
      callback: () => {
        this.spawnRottenFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 400,
          callback: () => {
            this.spawnFruit(startingPosition + 50);
            this.spawnEvent = this.time.addEvent({
              delay: 400,
              callback: () => {
                this.spawnDanger(startingPosition + 100);
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  danger_danger_pattern_1() {
    const randomNum = Math.floor(Math.random() * 4) + 4;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 300,
          callback: () => {
            this.spawnDanger(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 300,
              callback: () => {
                this.spawnFruit(startingPosition - 100);
                this.spawnEvent = this.time.addEvent({
                  delay: 300,
                  callback: () => {
                    this.spawnDanger(startingPosition - 150);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  danger_danger_pattern_2() {
    const randomNum = Math.floor(Math.random() * 6) + 2;
    const startingPosition = 50 * randomNum + 40;
    this.spawnEvent = this.time.addEvent({
      delay: 300,
      callback: () => {
        this.spawnFruit(startingPosition);
        this.spawnEvent = this.time.addEvent({
          delay: 300,
          callback: () => {
            this.spawnDanger(startingPosition - 50);
            this.spawnEvent = this.time.addEvent({
              delay: 300,
              callback: () => {
                this.spawnFruit(startingPosition);
                this.spawnEvent = this.time.addEvent({
                  delay: 300,
                  callback: () => {
                    this.spawnDanger(startingPosition - 50);
                  },
                  callbackScope: this,
                });
              },
              callbackScope: this,
            });
          },
          callbackScope: this,
        });
      },
      callbackScope: this,
    });
  }

  spawnFruit(x) {
    const isRed = Math.random() < 0.5 && this.redFruitCount < 5;
    if (isRed) {
      this.redFruitCount++;
    }
    const fallingImage = this.physics.add
      .sprite(x, -50, isRed ? "fruitRed" : "fruitGreen")
      .setDepth(1)
      .setScale(0.3);
    fallingImage.type = isRed ? "redFruit" : "greenFruit";
    fallingImage.isActive = true;
    fallingImage.score = isRed ? 2 : 1;
    this.fallingFruitsGroup.add(fallingImage);
  }

  spawnRottenFruit(x) {
    const fallingImage = this.physics.add
      .sprite(x, -50, "fruitRotten")
      .setDepth(1)
      .setScale(0.3);
    fallingImage.type = "rottenFruit";
    fallingImage.score = -1;
    fallingImage.isActive = true;
    this.fallingFruitsGroup.add(fallingImage);
  }

  spawnDanger(x) {
    const isCaterpilla = Math.random() < 0.5;
    const fallingImage = this.physics.add
      .sprite(x, -50, isCaterpilla ? "caterpilla" : "spriteSnake")
      .setDepth(1)
      .setScale(isCaterpilla ? 0.3 : 0.6);
    fallingImage.type = isCaterpilla ? "caterpilla" : "snake";
    fallingImage.score = 0;
    fallingImage.isActive = true;
    if (!isCaterpilla) {
      fallingImage.play("SnakeDance");
    }

    this.fallingFruitsGroup.add(fallingImage);
  }
}