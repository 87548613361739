import Phaser from "phaser";
import LoadingScene from "@/games/KombatTutorial/scenes/LoadingScene";
import LandingScene from "@/games/KombatTutorial/scenes/LandingScene";
import QuestionScene from "@/games/KombatTutorial/scenes/QuestionScene";
import PlayScene from "@/games/KombatTutorial/scenes/PlayScene";
import Settings from "@/games/KombatTutorial/scenes/Settings";

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    dom: {
      createContainer: true,
    },
    scene: [LoadingScene, LandingScene, QuestionScene, PlayScene, Settings],
  });
}

export default launch;
export { launch };
