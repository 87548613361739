import { Scene } from "phaser";
import store from "@/store/index.js";
import i18n from "@/plugins/i18n";
var _ = require("lodash");
import router from "@/router";
import RexUIPlugin from "phaser3-rex-plugins/templates/ui/ui-plugin.js";

export default class Settings extends Scene {
  constructor() {
    super({ key: "Settings" });
  }

  init(data) {
    this.sceneName = data.sceneName;
  }

  preload() {
    this.load.scenePlugin({
      key: "rexuiplugin",
      url: RexUIPlugin,
      sceneKey: "rexUI",
    });
  }

  create() {
    var settings = store.state.settings.data;
    var title;
    var lang;
    var sound;
    var soundBtn;
    var langBtn;
    var exitBtn;
    var exit;
    var cache;
    var cacheBtn;
    var cacheOnOffBtn;
    var cacheMsg;
    var cacheOnOff;
    var closeBtn;
    var textMaster;
    var sliderMaster;
    var btnSfx;
    var btnMusic;
    var soundButtons;
    var yesBtn;
    var noBtn;
    var yes;
    var no;
    var confirm;
    var langButtons = [];
    var langs = [
      {
        name: "Bahasa Melayu",
        value: "ms",
      },
      {
        name: "中文",
        value: "zh",
      },
      {
        name: "English",
        value: "en",
      },
    ];

    const COLOR_LIGHT = 0xfad60f;
    const COLOR_DARK = 0x260e04;

    // Sounds
    this.closeSound = this.sound.add("sfxClose");
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.5);
    this.add.image(240, 310, "imgSettingPanel").setScale(1, 1.05);

    closeBtn = this.add.image(360, 180, "imgCloseBtn").setScale(0.3);
    closeBtn.on("clicked", clickClose, this);
    closeBtn.setInteractive({ useHandCursor: true });

    title = this.add.text(110, 170, i18n.t("string.settings"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });
    soundBtn = this.add.image(240, 240, "imgOptionBtn").setScale(0.35);
    sound = this.add.text(260, 240, i18n.t("string.audio"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });
    textMaster = this.add.text(200, 240, i18n.t("string.volume"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });

    sound.on("clicked", clickSound, this);
    sound.setOrigin(0.5);
    sound.setPadding(10);
    sound.setInteractive({ useHandCursor: true });
    sound.setFixedSize(250, 40);

    langBtn = this.add.image(240, 300, "imgOptionBtn").setScale(0.35);
    lang = this.add.text(260, 300, i18n.t("string.language"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });
    lang.on("clicked", clickLang, this);
    lang.setOrigin(0.5);
    lang.setPadding(10);
    lang.setInteractive({ useHandCursor: true });
    lang.setFixedSize(250, 40);

    sliderMaster = this.rexUI.add
      .slider({
        x: 240,
        y: 280,
        width: 220,
        height: 20,
        orientation: "x",
        value: settings.audio.master,
        track: this.rexUI.add.roundRectangle(0, 0, 0, 0, 6, COLOR_DARK),
        indicator: this.rexUI.add.roundRectangle(0, 0, 0, 0, 6, COLOR_LIGHT),
        thumb: this.rexUI.add.roundRectangle(0, 0, 0, 0, 10, COLOR_LIGHT),

        valuechangeCallback: function (value) {
          var tempSettings = _.cloneDeep(settings);
          tempSettings.audio.master = value;
          settings = tempSettings;
          store.commit("updateSettings", tempSettings);
        },
        space: {
          top: 4,
          bottom: 4,
        },
        input: "drag", // 'drag'|'click'
      })
      .layout();

    btnSfx = this.rexUI.add.label({
      width: 100,
      height: 40,
      text: this.add.text(0, 0, i18n.t("string.sfx"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      }),
      icon: this.add.circle(0, 0, 10).setStrokeStyle(1, ""),
      value: settings.audio.sfx,
      space: {
        left: 10,
        right: 10,
        icon: 10,
      },
      name: "SFX",
    });

    btnMusic = this.rexUI.add.label({
      width: 100,
      height: 40,

      text: this.add.text(0, 0, i18n.t("string.bgMusic"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      }),
      icon: this.add.circle(0, 0, 10).setStrokeStyle(1, ""),
      value: settings.audio.music,
      space: {
        left: 10,
        right: 10,
        icon: 10,
      },

      name: "Background Music",
    });

    soundButtons = this.rexUI.add
      .buttons({
        x: 238,
        y: 350,
        orientation: "y",
        type: "checkboxes",
        buttons: [btnSfx, btnMusic],
        setValueCallback: function (button, value, previousValue) {
          var tempSettings = _.cloneDeep(settings);
          if (previousValue != undefined) {
            if (button.name == "SFX") {
              tempSettings.audio.sfx = tempSettings.audio.sfx == 1 ? 0 : 1;
            } else {
              tempSettings.audio.music = tempSettings.audio.music == 1 ? 0 : 1;
            }
            settings = tempSettings;
            store.commit("updateSettings", tempSettings);
          }

          if (button.name == "SFX") {
            button
              .getElement("icon")
              .setFillStyle(
                tempSettings.audio.sfx == 1 ? COLOR_LIGHT : COLOR_DARK
              );
          } else {
            button
              .getElement("icon")
              .setFillStyle(
                tempSettings.audio.music == 1 ? COLOR_LIGHT : COLOR_DARK
              );
          }
        },
      })
      .layout();

    for (var i = 0; i < langs.length; i++) {
      var button = this.add
        .text(260, 280 + i * 60, langs[i].name, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#000",
        })
        .setDepth(9);
      button.btn = this.add
        .image(240, 280 + i * 60, "imgOptionBtn")
        .setScale(0.35)
        .setDepth(8);
      button.on("clicked", changeLang, this);
      button.setOrigin(0.5);
      button.setPadding(10);
      button.value = langs[i].value;
      button.setInteractive({ useHandCursor: true });
      button.setFixedSize(250, 40);
      langButtons.push(button);
    }

    sliderMaster.visible = false;
    textMaster.visible = false;
    soundButtons.visible = false;
    for (var j = 0; j < langButtons.length; j++) {
      langButtons[j].visible = false;
      langButtons[j].btn.visible = false;
    }
    this.input.on(
      "gameobjectup",
      function (pointer, gameObject) {
        gameObject.emit("clicked", gameObject);
      },
      this
    );

    exitBtn = this.add.image(240, 420, "imgOptionBtn").setScale(0.35);
    exit = this.add.text(260, 420, i18n.t("string.exit"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });
    exit.on("clicked", clickExit, this);
    exit.setOrigin(0.5);
    exit.setPadding(10);
    exit.setInteractive({ useHandCursor: true });
    exit.setFixedSize(250, 40);

    yesBtn = this.add.image(240, 320, "imgOptionBtn").setScale(0.35);
    yes = this.add.text(260, 320, i18n.t("string.yes"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });
    yes.on("clicked", clickYes, this);
    yes.setOrigin(0.5);
    yes.setPadding(10);
    yes.setInteractive({ useHandCursor: true });
    yes.setFixedSize(250, 40);
    yesBtn.visible = false;

    yes.visible = false;

    noBtn = this.add.image(240, 390, "imgOptionBtn").setScale(0.35);
    no = this.add.text(260, 390, i18n.t("string.cancel"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });
    no.on("clicked", clickClose, this);
    no.setOrigin(0.5);
    no.setPadding(10);
    no.setInteractive({ useHandCursor: true });
    no.setFixedSize(250, 40);
    noBtn.visible = false;
    no.visible = false;

    confirm = this.add.text(240, 250, i18n.t("string.confirm_msg"), {
      fontFamily: "Sassoon, sans-serif",
      wordWrap: { width: 260 },
      fontSize: 20,
      color: "#000",
    });
    confirm.setOrigin(0.5);
    confirm.setPadding(10);
    confirm.setInteractive({ useHandCursor: true });
    confirm.setFixedSize(260, 80);
    confirm.visible = false;

    cacheBtn = this.add.image(240, 360, "imgOptionBtn").setScale(0.35);
    cache = this.add.text(260, 360, i18n.t("miniGames.general.cache"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
    });
    cache.on("clicked", clickCache, this);
    cache.setOrigin(0.5);
    cache.setPadding(10);
    cache.setInteractive({ useHandCursor: true });
    cache.setFixedSize(250, 40);

    cacheMsg = this.add.text(
      240,
      250,
      store.state.settings.data.cache
        ? i18n.t("miniGames.general.cacheOn")
        : i18n.t("miniGames.general.cacheOff"),
      {
        fontFamily: "Sassoon, sans-serif",
        wordWrap: { width: 260 },
        fontSize: 20,
        color: "#000",
      }
    );
    cacheMsg.setOrigin(0.5);
    cacheMsg.setPadding(10);
    cacheMsg.setInteractive({ useHandCursor: true });
    cacheMsg.setFixedSize(260, 80);
    cacheMsg.visible = false;

    cacheOnOffBtn = this.add.image(240, 320, "imgOptionBtn").setScale(0.35);
    cacheOnOff = this.add.text(
      260,
      320,
      store.state.settings.data.cache ? i18n.t("miniGames.general.off") : i18n.t("miniGames.general.on"),
      {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      }
    );
    cacheOnOff.on("clicked", changeCache, this);
    cacheOnOff.setOrigin(0.5);
    cacheOnOff.setPadding(10);
    cacheOnOff.setInteractive({ useHandCursor: true });
    cacheOnOff.setFixedSize(250, 40);
    cacheOnOffBtn.visible = false;

    cacheOnOff.visible = false;

    function clickClose() {
      this.closeSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.closeSound.play();
      this.scene.stop("Settings");
      this.scene.resume(this.sceneName);
    }

    function changeCache() {
      this.closeSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      let tempSettings = _.cloneDeep(store.state.settings.data);
      tempSettings.cache = !tempSettings.cache;
      store.commit("updateSettings", tempSettings);
      this.closeSound.play();
      this.scene.stop("Settings");
      this.scene.resume(this.sceneName);
    }

    function clickYes() {
      this.closeSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      router.push({
        name: "PageEventLevels"
      });
    }

    function clickExit() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      title.setText(i18n.t("string.exit"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      });

      yesBtn.visible = true;
      noBtn.visible = true;
      yes.visible = true;
      no.visible = true;
      confirm.visible = true;
      sound.visible = false;
      lang.visible = false;
      exit.visible = false;
      cache.visible = false;
      soundBtn.visible = false;
      langBtn.visible = false;
      exitBtn.visible = false;
      cacheBtn.visible = false;
    }

    function clickCache() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      title.setText(i18n.t("miniGames.general.cache"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      });

      cacheOnOffBtn.visible = true;
      cacheOnOff.visible = true;
      cacheMsg.visible = true;
      sound.visible = false;
      lang.visible = false;
      exit.visible = false;
      cache.visible = false;
      soundBtn.visible = false;
      langBtn.visible = false;
      exitBtn.visible = false;
      cacheBtn.visible = false;
    }

    function clickSound() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      title.setText(i18n.t("string.audio"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      });
      soundButtons.visible = true;
      textMaster.visible = true;
      sliderMaster.visible = true;
      sound.visible = false;
      lang.visible = false;
      exit.visible = false;
      cache.visible = false;
      soundBtn.visible = false;
      langBtn.visible = false;
      exitBtn.visible = false;
      cacheBtn.visible = false;
    }
    function clickLang() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      title.setText(i18n.t("string.language"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      });
      for (var j = 0; j < langButtons.length; j++) {
        langButtons[j].visible = true;
        langButtons[j].btn.visible = true;
      }
      sound.visible = false;
      lang.visible = false;
      exit.visible = false;
      cache.visible = false;
      soundBtn.visible = false;
      langBtn.visible = false;
      exitBtn.visible = false;
      cacheBtn.visible = false;
    }
    function changeLang(val) {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      var href = window.location.href;
      var path = window.location.pathname.replace(
        /\/[a-z]{2}/,
        "/" + val.value
      );
      var query = href.split("?")[1] ? `?${href.split("?")[1]}` : "";
      window.location.href = path + query;
    }
  }

  update() {
    //
  }
}
