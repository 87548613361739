import { Scene } from "phaser";
import store from "@/store";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.questions = data.questions;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  create() {
    this.bgSound = this.sound.add("bgm");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;
    this.bgSound.play();

    this.scene.launch("PlayScene", {
      score: 0,
      round: 0,
      avatar: this.avatar,
      name: this.name,
      questions: this.questions,
      difficulty: this.difficulty,
      noOfRounds: this.noOfRounds
    });
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
