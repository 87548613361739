import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";
import store from "@/store";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  selectedOption = -1;

  create() {
    this._bg = this.add.image(240, 427, "bg").setScale(0.5);
    this._logo = this.add.image(240, 327, "logo").setScale(0.8);

    // this._continueBtn = this.add.image(240, 450, "optionButton").setScale(0.35);
    // this._continueTxt = this.add.text(250, 450, i18n.t("miniGames.general.continue"), {
    //   fontFamily: "Sassoon, sans-serif",
    //   fontSize: 20,
    //   color: "#000",
    // });

    this.bgSound = this.sound.add("bg");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;

    // this._continueTxt.on(
    //   "pointerdown",
    //   () => {
    this.time.delayedCall(1000, () => {
      this._bg.visible = false;
      this._logo.visible = false;
      // this._continueBtn.visible = false;
      // this._continueTxt.visible = false;
      this.bgSound.play();
      this.scene.launch("RoundStartScene", {
        avatar: this.avatar,
        round: 1,
        questions: this.questions,
        friend: this.friend,
        score: 0,
        bossRound: this.selectedOption == 2 ? 1 : this.bossRound,
        forceFriend: this.selectedOption == 1 ? true : false,
        noOfRounds: this.noOfRounds,
        difficulty: this.difficulty,
      });
    });
    // },
    // this
    // );
    // this._continueTxt.setOrigin(0.5);
    // this._continueTxt.setPadding(10);
    // this._continueTxt.setInteractive({ useHandCursor: true });
    // this._continueTxt.setFixedSize(200, 40);
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
