import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import Phaser from "phaser";

export default class RoundStartScene extends Scene {
  constructor() {
    super({ key: "RoundStartScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.round = data.round;
    this.questions = data.questions;
    this.score = data.score;
    this.bossRound = data.bossRound;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.forceFriend = data.forceFriend;
    this.difficulty = data.difficulty;
  }

  avatarObject = [];
  chats = [];
  currentChatId = 0;

  create() {
    this.chats_1 = [i18n.t("miniGames.mining.tutorial.dialog_1"), i18n.t("miniGames.mining.tutorial.dialog_2")];

    this.chats_2 = [i18n.t("miniGames.mining.tutorial.dialog_3")];

    this.add.image(240, 427, "bg").setScale(0.5);
    this.add.image(240, 390, "wall").setScale(1.12);
    if (this.round != 1) {
      this.add.image(80, 35, "score").setScale(0.3);
      this.add.image(70, 70, "score").setScale(0.25, 0.2);
      this.scoreText = this.add
        .text(
          30,
          35,
          i18n.t("miniGames.general.score") + " : " + this.score + "",
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 18,
            color: "#000",
          }
        )
        .setOrigin(0, 0.5);

      this.add
        .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 15,
          color: "#000",
        })
        .setOrigin(0, 0.5);
    }

    this.settingBtn = this.add.image(440, 40, "setting").setScale(0.6);
    this.settingBtn.on("pointerdown", clickSetting, this);
    if (this.round != 1) {
      this.settingBtn.setInteractive({ useHandCursor: true });
    }

    // Sounds
    this.startGameSound = this.sound.add("startGame");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.roundSound = this.sound.add("round");
    this.emojiSound = this.sound.add("emoji");

    for (let j = 0; j < 4; j++) {
      for (let i = 0; i < 4; i++) {
        this.add.image(90 + i * 100, 240 + j * 100, "steelWall");
      }
    }

    // avatar
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.add.image(this.round == 1 ? -110 : 100, 720, key).setScale(0.3)
          );
          this.avatarObject.push(
            this.add
              .image(this.round == 1 ? -110 : 100, 720, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.3)
              .setAlpha(0.3)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.add.image(this.round == 1 ? -110 : 100, 720, key).setScale(0.3)
          );
        }
      }
    }

    this.speech = this.add.image(100, 620, "speech").setScale(0.0);
    this.mark = this.add.image(100, 620, "excl").setScale(0.0);
    this.modelbg = this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0);
    this.roundObj = [
      this.add.image(240, 427, "round").setScale(0),
      this.add
        .text(240, 427, i18n.t("miniGames.general.round") + " " + this.round, {
          fontFamily: "Sassoon, sans-serif",
          color: "#000000",
          fontSize: 32,
        })
        .setOrigin(0.5)
        .setScale(0),
    ];

    this.startBtn = this.add.image(380, 750, "play").setScale(0);

    this.startBtn.on(
      "pointerdown",
      () => {
        this.startGameSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.startGameSound.play();
        this.avatarObject = [];
        this.registry.destroy();
        this.events.off();
        this.scene.stop("RoundStartScene");
        this.scene.start("QuestionScene", {
          avatar: this.avatar,
          round: this.round,
          questions: this.questions,
          score: this.score,
          friend: this.friend,
          bossRound: this.bossRound,
          noOfRounds: this.noOfRounds,
          forceFriend: this.forceFriend,
          difficulty: this.difficulty,
        });
      },
      this
    );

    // Tutorial
    this.tutorialBackground = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0);
    this.tutorialBackground.setInteractive().on("pointerdown", () => {
      this.tweens.add({
        targets: this.tutorialChat,
        x: this.tutorialChat.x + Phaser.Math.Between(-5, 5),
        y: this.tutorialChat.y + Phaser.Math.Between(-5, 5),
        duration: 50,
        repeat: 2,
        yoyo: true,
      });
      this.tweens.add({
        targets: this.tutorialChat.chat,
        x: this.tutorialChat.chat.x + Phaser.Math.Between(-5, 5),
        y: this.tutorialChat.chat.y + Phaser.Math.Between(-5, 5),
        duration: 50,
        repeat: 2,
        yoyo: true,
      });
      this.tweens.add({
        targets: this.tutorialChat.text,
        x: this.tutorialChat.text.x + Phaser.Math.Between(-5, 5),
        y: this.tutorialChat.text.y + Phaser.Math.Between(-5, 5),
        duration: 50,
        repeat: 2,
        yoyo: true,
      });
      this.tweens.add({
        targets: this.tutorialChat.button,
        x: this.tutorialChat.button.x + Phaser.Math.Between(-5, 5),
        y: this.tutorialChat.button.y + Phaser.Math.Between(-5, 5),
        duration: 50,
        repeat: 2,
        yoyo: true,
      });
      this.tweens.add({
        targets: this.tutorialChat.buttonText,
        x: this.tutorialChat.buttonText.x + Phaser.Math.Between(-5, 5),
        y: this.tutorialChat.buttonText.y + Phaser.Math.Between(-5, 5),
        duration: 50,
        repeat: 2,
        yoyo: true,
      });
    });

    this.anims.create({
      key: "click_anim",
      frames: this.anims.generateFrameNumbers("click_sprite", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
    this.tutorialClick = this.add.sprite(420, 800, "click_sprite").setScale(0);
    this.tutorialClick.play("click_anim");

    this.tutorialChat = this.add.graphics();
    this.tutorialChat.lineStyle(2, 0x00ff00);
    this.tutorialChat.fillStyle(0xffffff, 0.8);
    this.tutorialChat.fillRoundedRect(10, 690, 460, 154, 20);

    const rect = new Phaser.Geom.Rectangle(10, 690, 460, 154);
    this.tutorialChat.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
    this.tutorialChat.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.chat = this.add.image(90, 765, "hamochi_talk").setScale(0.25);

    this.tutorialChat.text = this.add.text(170, 750, "", {
      fontSize: "20px",
      wordWrap: { width: 300 },
      fontFamily: "Sassoon, sans-serif",
      color: "#000000",
      align: "left",
    });

    this.tutorialChat.text.setOrigin(0, 0.5);

    this.tutorialChat.button = this.add
      .graphics()
      .fillStyle(0xc7c7c7, 1)
      .fillRoundedRect(314, 812, 144, 20, 10);

    this.tutorialChat.buttonText = this.add.text(
      320,
      815,
      "▼" + i18n.t("action.clickToContinue"),
      {
        fontSize: "12px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      }
    );

    this.tutorialChat.buttonText.setInteractive({ useHandCursor: true });

    this.tutorialChat.buttonText.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.setAlpha(0);
    this.tutorialChat.chat.setAlpha(0);
    this.tutorialChat.text.setAlpha(0);
    this.tutorialChat.button.setAlpha(0);
    this.tutorialChat.buttonText.setAlpha(0);

    // Start tutorial
    if (this.round == 1) {
      this.chats = this.chats_1;
      this.tutorialChat.text.setText(this.chats[this.currentChatId]);
      this.startTutorial();
    } else {
      this.avatarEnter();
      this.startBtn.setInteractive({ useHandCursor: true });
    }

    function clickSetting() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();
      this.scene.pause();
      this.scene.launch("Settings", {
        sceneName: "RoundStartScene",
      });
    }
  }

  continueChat() {
    this.currentChatId++;
    if (this.chats[this.currentChatId]) {
      this.tweens.add({
        targets: this.tutorialChat.text,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.text.setText(this.chats[this.currentChatId]);
          this.tweens.add({
            targets: this.tutorialChat.text,
            alpha: 1,
            duration: 100,
            ease: "Sine.easeOut",
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [
          this.tutorialChat,
          this.tutorialChat.chat,
          this.tutorialChat.text,
          this.tutorialChat.button,
          this.tutorialChat.buttonText,
          this.tutorialBackground,
        ],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeOut",
        onComplete: () => {
          if (this.showPickaxe) {
            this.showPickaxe = false;
            this.tweens.add({
              onStart: () => {
                this.startBtn.setDepth(10);
                this.tutorialClick.setDepth(10);
              },
              targets: this.tutorialBackground,
              alpha: 0.4,
              duration: 200,
              ease: "Sine.easeOut",
              delay: 1000,
              onComplete: () => {
                this.tweens.add({
                  targets: this.tutorialClick,
                  scaleX: 0.5,
                  scaleY: 0.5,
                  duration: 100,
                  ease: "Sine.easeInOut",
                  delay: 200,
                  onComplete: () => {
                    this.startBtn.setInteractive({ useHandCursor: true });
                  },
                });
              },
            });
          } else {
            this.avatarEnter();
          }
        },
      });
    }
  }

  avatarEnter() {
    if (this.round == 1) {
      this.tweens.add({
        targets: this.avatarObject,
        x: 100,
        duration: 1500,
        ease: "Power2",
        repeat: 0,
        delay: 500,
        onComplete: () => {
          this.tweens.add({
            targets: [this.mark, this.speech],
            scaleX: 0.3,
            scaleY: 0.3,
            duration: 300,
            ease: "Sine.easeOut",
            onStart: () => {
              this.emojiSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.emojiSound.play();
            },
          });
        },
      });

      this.tweens.add({
        targets: this.avatarObject,
        scaleY: 0.35,
        scaleX: 0.29,
        y: 710,
        duration: 700,
        ease: "Sine.easeInOut",
        repeat: 3,
        yoyo: true,
        onComplete: () => {
          this.tweens.add({
            targets: this.avatarObject,
            scaleY: 0.31,
            scaleX: 0.29,
            y: 715,
            duration: 2000,
            ease: "Sine.easeInOut",
            repeat: -1,
            yoyo: true,
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [this.mark, this.speech],
        scaleX: 0.3,
        scaleY: 0.3,
        duration: 300,
        ease: "Sine.easeOut",
        onStart: () => {
          this.emojiSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.emojiSound.play();
        },
      });
      this.tweens.add({
        targets: this.avatarObject,
        scaleY: 0.31,
        scaleX: 0.29,
        y: 715,
        duration: 2000,
        ease: "Sine.easeInOut",
        repeat: -1,
        yoyo: true,
      });
    }

    this.tweens.add({
      targets: this.modelbg,
      alpha: 0.4,
      duration: 200,
      ease: "Sine.easeOut",
      delay: this.round == 1 ? 3000 : 1000,
    });
    this.tweens.add({
      targets: this.roundObj,
      scaleX: 1,
      scaleY: 1,
      duration: 200,
      ease: "Sine.easeOut",
      delay: this.round == 1 ? 3000 : 1000,
      onStart: () => {
        this.roundSound.setVolume(
          store.state.settings.data.audio.sfx *
            store.state.settings.data.audio.master
        );
        this.roundSound.play();
      },
      onComplete: () => {
        this.tweens.add({
          targets: this.roundObj,
          scaleX: 0,
          scaleY: 0,
          duration: 200,
          ease: "Sine.easeOut",
          delay: 1000,
        });
        this.tweens.add({
          targets: this.modelbg,
          alpha: 0,
          duration: 200,
          ease: "Sine.easeOut",
          delay: 1000,
          onComplete: () => {
            this.tweens.add({
              targets: this.startBtn,
              scaleX: 0.25,
              scaleY: 0.25,
              duration: 200,
              ease: "Sine.easeOut",
              delay: 500,
              onComplete: () => {
                if (this.round == 1) {
                  this.currentChatId = 0;
                  this.chats = this.chats_2;
                  this.tutorialChat.text.setText(
                    this.chats[this.currentChatId]
                  );
                  this.showPickaxe = true;
                  this.startTutorial();
                }

                this.tweens.add({
                  targets: this.startBtn,
                  scaleX: 0.27,
                  scaleY: 0.27,
                  duration: 500,
                  ease: "Sine.easeInOut",
                  repeat: -1,
                  yoyo: true,
                });
              },
            });
          },
        });
      },
    });
  }

  startTutorial() {
    this.tweens.add({
      targets: this.tutorialBackground,
      alpha: 0.4,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onComplete: () => {
        this.tweens.add({
          targets: this.tutorialChat,
          alpha: 1,
          duration: 100,
          ease: "Sine.easeOut",
          delay: 500,
          onComplete: () => {
            this.tweens.add({
              targets: this.tutorialChat.chat,
              alpha: 1,
              duration: 500,
              ease: "Sine.easeOut",
              onComplete: () => {
                this.tweens.add({
                  targets: [
                    this.tutorialChat.text,
                    this.tutorialChat.button,
                    this.tutorialChat.buttonText,
                  ],
                  alpha: 1,
                  duration: 500,
                  ease: "Sine.easeOut",
                });
              },
            });
          },
        });
      },
    });
  }

  update() {
    //
  }
}
