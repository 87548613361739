import { Scene } from "phaser";
import Phaser from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";

export default class AnsWrongPlayScene extends Scene {
  constructor() {
    super({ key: "AnsWrongPlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.score = data.score;
    this.round = data.round;
    this.questions = data.questions;
    this.bossRound = data.bossRound;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
    this.countCorrect = data.countCorrect;
    this.avatarX = data.avatarX;
  }

  avatarObject = [];
  fallingFruitsGroup;
  isGameOngoing = true;
  isCollisionEnabled = true;
  canMove = false;
  totalCountForStop = 0;

  chats = [];
  currentChatId = 0;

  create() {

    this.chats_1 = [
      i18n.t("miniGames.foraging.tutorial.dialog_15"),
      i18n.t("miniGames.foraging.tutorial.dialog_16"),
    ];


    // Background
    this.backgroundImage = this.add
      .image(0, 0, "backgroundImage")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this.scoreBoard = this.add.image(80, 35, "score").setScale(0.3).setDepth(2);
    this.resultBoard = this.add
      .image(70, 70, "score")
      .setScale(0.25, 0.2)
      .setDepth(2);
    this.settingBtn = this.add
      .image(440, 40, "setting")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);

    this.scoreText = this.add
      .text(
        30,
        35,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 18,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(2);
    this.roundText = this.add
      .text(30, 70, i18n.t("miniGames.general.round") + " " + this.round, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 15,
        color: "#492a12",
      })
      .setOrigin(0, 0.5)
      .setDepth(2);


    // Animations
    this.spriteHit = this.add
      .sprite(0, 535, "spriteHit")
      .setScale(0.5)
      .setAlpha(0);
    this.anims.create({
      key: "spikeBallHitting",
      frames: this.anims.generateFrameNumbers("spriteHit", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7],
      }),
      frameRate: 10,
    });

    this.spriteDizzy = this.add
      .sprite(0, 550, "spriteDizzy")
      .setScale(1)
      .setAlpha(0)
      .setDepth(3);
    this.anims.create({
      key: "avatarDizzy",
      frames: this.anims.generateFrameNumbers("spriteDizzy", {
        frames: [0, 1, 0, 1, 0, 1, 2],
      }),
      frameRate: 5,
    });

    this.spriteEnergy = this.add
      .sprite(0, 0, "spriteEnergy")
      .setScale(0.7)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarEnergy",
      frames: this.anims.generateFrameNumbers("spriteEnergy", {
        frames: [2, 3, 4, 5],
      }),
      frameRate: 5,
      repeat: -1,
    });

    this.spriteLand = this.add
      .sprite(0, 0, "spriteLand")
      .setScale(0.5)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarLand",
      frames: this.anims.generateFrameNumbers("spriteLand", {
        frames: [0, 1, 2, 3, 4, 5],
      }),
      frameRate: 10,
    });

    this.spriteJump = this.add
      .sprite(0, 0, "spriteJump")
      .setScale(2)
      .setAlpha(0)
      .setDepth(5);
    this.anims.create({
      key: "avatarJump",
      frames: this.anims.generateFrameNumbers("spriteJump", {
        frames: [0, 1, 2, 3],
      }),
      frameRate: 5,
    });

    this.anims.create({
      key: "snakeDestroy",
      frames: this.anims.generateFrameNumbers("spriteSnakeDestory", {
        frames: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      }),
      frameRate: 10,
    });

    // NPC and Drum
    this.npc = this.add.image(60, 630, "npc").setScale(0.5);
    this.drum = this.add.image(130, 560, "drum").setScale(0.4);

    // Sounds
    this.simpleClickSound = this.sound.add("simpleClick");
    this.avatarJumpSound = this.sound.add("avatarJumpSound");
    this.avatarGroundSound = this.sound.add("avatarGroundSound");

    // Player Avarar and Basket
    this.basket = this.physics.add
      .sprite(this.avatarX, 610, "basket")
      .setScale(0.4)
      .setDepth(2)
      .setAlpha(0);
    this.basketTop = this.add
      .rectangle(0, 0, 75, 5, 0xff0000)
      .setAlpha(0)
      .setDepth(2);
    this.physics.world.enable(this.basketTop);
    this.basketTop.body.setCollideWorldBounds(true);
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(5)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(5)
          );
        } else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(this.avatarX, 660, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(5)
          );
        }
      }
    }
    this.spriteEnergy.setAlpha(1);
    this.spriteEnergy.play("avatarEnergy");

    this.tutorialBackground = this.add.rectangle(240, 427, 480, 854, 0x000000);
    this.tutorialBackground.setInteractive().on("pointerdown", () => {
      if (!this.onShake) {
        this.onShake = true;
        this.tweens.add({
          targets: this.tutorialChat,
          x: this.tutorialChat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
          onComplete: () => {
            this.onShake = false;
          },
        });
        this.tweens.add({
          targets: this.tutorialChat.chat,
          x: this.tutorialChat.chat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.chat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.text,
          x: this.tutorialChat.text.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.text.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.button,
          x: this.tutorialChat.button.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.button.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.buttonText,
          x: this.tutorialChat.buttonText.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.buttonText.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
      }
    });
    this.tutorialChat = this.add.graphics();
    this.tutorialChat.lineStyle(2, 0x00ff00);
    this.tutorialChat.fillStyle(0xffffff, 0.8);
    this.tutorialChat.fillRoundedRect(10, 680, 460, 154, 20);

    this.anims.create({
      key: "click_anim",
      frames: this.anims.generateFrameNumbers("click_sprite", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
    this.tutorialClick = this.add
      .sprite(0, 0, "click_sprite")
      .setScale(0)
      .setDepth(10)
      .play("click_anim");

    const rect = new Phaser.Geom.Rectangle(10, 680, 460, 154);
    this.tutorialChat.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
    this.tutorialChat.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.chat = this.add
      .image(90, 755, "hamochi_talk")
      .setScale(0.25);

    this.tutorialChat.text = this.add.text(170, 740, "", {
      fontSize: "20px",
      wordWrap: { width: 300 },
      fontFamily: "Sassoon, sans-serif",
      color: "#000000",
      align: "left",
    });

    this.tutorialChat.text.setOrigin(0, 0.5);

    this.tutorialChat.button = this.add
      .graphics()
      .fillStyle(0xc7c7c7, 1)
      .fillRoundedRect(334, 802, 124, 20, 10);

    this.tutorialChat.buttonText = this.add.text(
      340,
      805,
      "▼" + i18n.t("action.clickToContinue"),
      {
        fontSize: "12px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      }
    );

    this.tutorialChat.buttonText.setInteractive({ useHandCursor: true });

    this.tutorialChat.buttonText.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialBackground.setDepth(9);
    this.tutorialChat.setDepth(9);
    this.tutorialChat.chat.setDepth(9);
    this.tutorialChat.text.setDepth(9);
    this.tutorialChat.button.setDepth(9);
    this.tutorialChat.buttonText.setDepth(9);

    this.tutorialBackground.setAlpha(0);
    this.tutorialChat.setAlpha(0);
    this.tutorialChat.chat.setAlpha(0);
    this.tutorialChat.text.setAlpha(0);
    this.tutorialChat.button.setAlpha(0);
    this.tutorialChat.buttonText.setAlpha(0);

    this.tweens.add({
      targets: [this.basket],
      y: 600,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
    this.tweens.add({
      targets: this.avatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: 655,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    // User control
    this.leftArrowBtn = this.add
      .image(120, 775, "leftArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingLeft, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.rightArrowBtn = this.add
      .image(360, 775, "rightArrowBtnImage")
      .setScale(0.28)
      .on("pointerdown", this.startMovingRight, this)
      .on("pointerup", this.stopMoving, this)
      .setDepth(2);

    this.input.keyboard.on("keydown-LEFT", this.startMovingLeft, this);
    this.input.keyboard.on("keydown-RIGHT", this.startMovingRight, this);
    this.input.keyboard.on("keyup-LEFT", this.stopMoving, this);
    this.input.keyboard.on("keyup-RIGHT", this.stopMoving, this);

    this.fallingFruitsGroup = this.add.group();

    // Game transitions starts here
    this.darkBack = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setAlpha(0.7)
      .setDepth(3);

    this.time.delayedCall(1000, () => {
      this.spriteEnergy.visible = false;
      this.spriteJump.y = this.avatarObject[0].y;
      this.spriteJump.setAlpha(1);
      this.spriteJump.play("avatarJump");

      this.tweens.add({
        targets: this.avatarObject,
        scaleY: 0.22,
        y: "+=10",
        ease: "Power1",
        duration: 100,
        yoyo: true,
        onStart: () => {
          if (this.avatarMask) {
            this.avatarMask.destroy();
          }
          this.avatarMask = this.add
            .image(this.avatarObject[0].x, this.avatarObject[0].y, "push")
            .setScale(0.25)
            .setDepth(6);
          this.avatarJumpSound.setVolume(
            store.state.settings.data.audio.sfx *
              store.state.settings.data.audio.master
          );
          this.avatarJumpSound.play();
        },
        onComplete: () => {
          this.tweens.add({
            targets: this.avatarObject,
            y: "-=400",
            ease: "Power1",
            duration: 700,
            yoyo: true,
            onStart: () => {
              this.avatarGroundSound.setVolume(
                store.state.settings.data.audio.sfx *
                  store.state.settings.data.audio.master
              );
              this.avatarGroundSound.play();
            },
            onComplete: () => {
              this.spriteJump.visible = false;
              this.spriteLand.y = this.avatarObject[0].y;
              this.spriteLand.setAlpha(1);
              this.spriteLand.play("avatarLand");
              this.darkBack.visible = false;
              this.cameras.main.shake(200, 0.01);

              this.tweens.add({
                targets: this.avatarObject,
                scaleY: 0.22,
                y: "+=10",
                ease: "Power1",
                duration: 100,
                yoyo: true,
                onComplete: () => {
                  if (this.avatarMask) {
                    this.avatarMask.destroy();
                  }
                  this.time.delayedCall(500, () => {
                    this.spriteLand.visible = false;
                    this.basket.setAlpha(1);
                    this.leftArrowBtn.setInteractive({ useHandCursor: true });
                    this.rightArrowBtn.setInteractive({ useHandCursor: true });
                    this.canMove = true;
                    this.npcMask = this.add
                      .image(60, 615, "fear")
                      .setScale(0.25);
                    if (this.avatarMask) {
                      this.avatarMask.destroy();
                    }
                    this.avatarMask = this.add
                      .image(
                        this.avatarObject[0].x,
                        this.avatarObject[0].y,
                        "sad"
                      )
                      .setScale(0.25)
                      .setDepth(6);

                    this.time.addEvent({
                      callback: () => {
                        var startingPosition = this.danger_pattern_1();
                        this.time.addEvent({
                          delay: 1200,
                          callback: () => {
                            this.danger_pattern_2(startingPosition);
                          },
                          callbackScope: this,
                        });
                      },
                      callbackScope: this,
                    });
                  });
                },
              });
            },
          });
        },
      });
    });

    this.spikeHitSound = this.sound.add("spikeHitSound");

    this.physics.add.collider(
      this.basketTop,
      this.fallingFruitsGroup,
      this.handleCollision,
      null,
      this
    );
  }

  startTutorial() {
    this.tweens.add({
      targets: this.tutorialBackground,
      alpha: 0.7,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onComplete: () => {
        this.tweens.add({
          targets: this.tutorialChat,
          alpha: 1,
          duration: 100,
          ease: "Sine.easeOut",
          delay: 500,
          onComplete: () => {
            this.tweens.add({
              targets: this.tutorialChat.chat,
              alpha: 1,
              duration: 500,
              ease: "Sine.easeOut",
              onComplete: () => {
                this.tweens.add({
                  targets: [
                    this.tutorialChat.text,
                    this.tutorialChat.button,
                    this.tutorialChat.buttonText,
                  ],
                  alpha: 1,
                  duration: 500,
                  ease: "Sine.easeOut",
                });
              },
            });
          },
        });
      },
    });
  }

  continueChat() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.currentChatId++;
    if (this.chats[this.currentChatId]) {
      this.tweens.add({
        targets: this.tutorialChat.text,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.text.setText(this.chats[this.currentChatId]);
          this.tweens.add({
            targets: this.tutorialChat.text,
            alpha: 1,
            duration: 100,
            ease: "Sine.easeOut",
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [
          this.tutorialChat,
          this.tutorialChat.chat,
          this.tutorialChat.text,
          this.tutorialChat.button,
          this.tutorialChat.buttonText,
          this.tutorialBackground,
        ],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeOut",
      });
      this.time.delayedCall(1000, () => {
        router.push({
          name: "PageEventGameTutorialCompleted",
        });
      });
    }
  }

  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "AnsWrongPlayScene",
    });
  }

  startMovingLeft() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(-300);
      }
    }
  }

  startMovingRight() {
    this.stopMoving();
    if (this.canMove) {
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(300);
      }
    }
  }

  stopMoving() {
    for (var i = 0; i < this.avatarObject.length; i++) {
      this.avatarObject[i].setVelocityX(0);
    }
  }

  handleCollision(x, fallingImage) {
    if (this.isCollisionEnabled) {
      fallingImage.destroy();

      this.spikeHitSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.spikeHitSound.play();

      this.spriteHit.setAlpha(1);
      this.spriteHit.play("spikeBallHitting");
      this.spriteDizzy.setAlpha(1);
      this.spriteDizzy.play("avatarDizzy");

      if (this.avatarMask) {
        this.avatarMask.destroy();
      }
      this.avatarMask = this.add
        .image(this.avatarObject[0].x, this.avatarObject[0].y, "stun")
        .setScale(0.25)
        .setDepth(6);

      this.avatarMask.spike = true;
      this.stopMoving();
      this.canMove = false;
      for (var i = 0; i < this.avatarObject.length; i++) {
        this.avatarObject[i].setVelocityX(0);
      }
      this.isCollisionEnabled = false;

      this.time.delayedCall(1000, () => {
        this.isGameOngoing = false;
        this.gameFinish();
        this.time.removeEvent(this.spawnEvent);
      });
    }
  }

  update() {
    if (this.avatarObject[0]) {
      this.basket.x = this.avatarObject[0].x;
      this.spriteEnergy.x = this.avatarObject[0].x;
      this.spriteEnergy.y = this.avatarObject[0].y - 10;
      this.spriteLand.x = this.avatarObject[0].x;
      this.spriteJump.x = this.avatarObject[0].x;
    }
    if (this.basket) {
      this.spriteHit.x = this.basket.x;
      this.spriteDizzy.x = this.basket.x;
      this.basketTop.x = this.basket.x;
      this.basketTop.y = this.basket.y - 40;
    }
    if (this.avatarMask && this.avatarObject[0]) {
      this.avatarMask.x = this.avatarObject[0].x;
      this.avatarMask.y = this.avatarObject[0].y;
    }

    if (this.totalCountForStop >= 10 && this.isGameOngoing) {
      this.isGameOngoing = false;
      this.gameFinish();
      this.time.removeEvent(this.spawnEvent);
    }

    this.fallingFruitsGroup.getChildren().forEach(function (fallingImage) {
      
      if (fallingImage.y >= 700) {
        if (fallingImage.isActive) {
          fallingImage.isActive = false;
          if (
            fallingImage.type == "caterpilla" ||
            fallingImage.type == "snake"
          ) {
            let xSprite = this.add
              .sprite(fallingImage.x, fallingImage.y - 20, "spriteSnakeDestory")
              .setScale(0.6)
              .setDepth(5)
              .play("snakeDestroy")
              .on("animationcomplete", () => {
                xSprite.destroy();
              });
            fallingImage.destroy();
          }
          this.totalCountForStop++;
        }
        
      }else{
        fallingImage.y += 3;
      }
    }, this);
  }

  gameFinish() {
    this.time.delayedCall(1500, () => {
      this.stopMoving();
      this.canMove = false;
      if (this.round != this.noOfRounds) {
        let avatarX = this.avatarObject[0].x;
        this.avatarObject = [];
        this.fallingFruitsGroup;
        this.canMove = false;
        this.totalCountForStop = 0;
        this.isCollisionEnabled = true;
        this.isGameOngoing = true;
        this.scene.start("PlayScene", {
          avatar: this.avatar,
          round: this.round + 1,
          score: this.score,
          questions: this.questions,
          friend: this.friend,
          bossRound: this.bossRound,
          noOfRounds: this.noOfRounds,
          difficulty: this.difficulty,
          countCorrect: this.countCorrect,
          avatarX: avatarX,
        });
      } else {
        this.currentChatId = 0;
        this.chats = this.chats_1;
        this.tutorialChat.text.setText(this.chats[this.currentChatId]);
        this.startTutorial();
      }
    });
  }

  danger_pattern_1() {
    const randomNum = Math.floor(Math.random() * 5) + 1;
    const startingPosition = 50 * randomNum + 40;

    for (let index = 40; index < 450; index += 50) {
      if (
        index != startingPosition &&
        index != startingPosition + 50 &&
        index != startingPosition + 100
      ) {
        this.spawnEvent = this.time.addEvent({
          callback: () => {
            this.spawnDanger(index);
          },
          callbackScope: this,
        });
      }
    }
    return startingPosition;
  }

  danger_pattern_2(startingPosition) {
    for (
      let index = startingPosition;
      index < startingPosition + 160;
      index += 50
    ) {
      this.spawnEvent = this.time.addEvent({
        callback: () => {
          this.spawnDanger(index);
        },
        callbackScope: this,
      });
    }
  }

  spawnDanger(x) {
    const isCaterpilla = Math.random() < 1;
    const fallingImage = this.physics.add
      .sprite(x, -50, isCaterpilla ? "caterpilla" : "spriteSnake")
      .setDepth(1)
      .setScale(isCaterpilla ? 0.3 : 0.6);
    fallingImage.type = isCaterpilla ? "caterpilla" : "snake";
    fallingImage.score = 0;
    fallingImage.isActive = true;
    if (!isCaterpilla) {
      fallingImage.play("SnakeDance");
    }

    this.fallingFruitsGroup.add(fallingImage);
  }
}
