import { Scene } from "phaser";
import i18n from "@/plugins/i18n";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images
    // buttons
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
    this.load.image("imgOkBtn", "/assets/gameAssets/common/images/btn-ok.png");
    this.load.image(
      "imgOptionBtn",
      "/assets/gameAssets/common/images/btn-option.png"
    );
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );

    // panel bg
    this.load.image(
      "imgRoundPanel",
      "/assets/gameAssets/common/images/panel-round.png"
    );
    this.load.image(
      "imgSettingPanel",
      "/assets/gameAssets/common/images/panel-setting.png"
    );
    this.load.image(
      "imgScorePanel",
      "/assets/gameAssets/common/images/panel-score.png"
    );
    this.load.image(
      "imgAnswerPanel",
      "/assets/gameAssets/common/images/panel-answer.png"
    );

    // others
    this.load.image(
      "imgAnswerTag",
      "/assets/gameAssets/common/images/answer-tag.png"
    );
    this.load.image(
      "imgCorrectAns",
      "/assets/gameAssets/common/images/answer-correct.png"
    );
    this.load.image(
      "imgWrongAns",
      "/assets/gameAssets/common/images/answer-wrong.png"
    );
    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );
    this.load.image(
      "imgExclMark",
      "/assets/gameAssets/common/images/mark-exclamation.png"
    );
    this.load.image("imgSpeech", "/assets/gameAssets/common/images/speech.png");

    // images specific to the game
    this.load.image(
      "imgBackground",
      "/assets/gameAssets/cookingGame/images/background.png"
    );
    this.load.image(
      "imgDoor",
      "/assets/gameAssets/cookingGame/images/door.png"
    );
    this.load.image(
      "imgCounter",
      "/assets/gameAssets/cookingGame/images/counter.png"
    );
    this.load.image(
      "imgGameTitle",
      "/assets/gameAssets/cookingGame/images/game-title.png"
    );
    this.load.image("imgNpc", "/assets/gameAssets/cookingGame/images/npc.png");
    this.load.image(
      "imgWoodPlane",
      "/assets/gameAssets/cookingGame/images/wood-plane.png"
    );
    this.load.image(
      "imgOrderBubble",
      "/assets/gameAssets/cookingGame/images/order-bubble.png"
    );
    this.load.image(
      "imgFireExting",
      "/assets/gameAssets/cookingGame/images/fire-exting.png"
    );
    this.load.image(
      "imgTrashBin",
      "/assets/gameAssets/cookingGame/images/trash-bin.png"
    );
    this.load.image(
      "imgFireExtingIcon",
      "/assets/gameAssets/cookingGame/images/fire-exting-icon.png"
    );
    this.load.image(
      "imgTrashBinIcon",
      "/assets/gameAssets/cookingGame/images/trash-bin-icon.png"
    );
    this.load.image(
      "imgCrab",
      "/assets/gameAssets/cookingGame/images/crab.png"
    );
    this.load.image(
      "imgSteak",
      "/assets/gameAssets/cookingGame/images/steak.png"
    );
    this.load.image(
      "imgWing",
      "/assets/gameAssets/cookingGame/images/wing.png"
    );
    this.load.image(
      "imgRushHour",
      "/assets/gameAssets/cookingGame/images/rush-hour.png"
    );
    this.load.image(
      "imgCake",
      "/assets/gameAssets/cookingGame/images/cake.png "
    );
    this.load.image(
      "imgJelly",
      "/assets/gameAssets/cookingGame/images/jelly.png"
    );
    this.load.image("imgEgg", "/assets/gameAssets/cookingGame/images/egg.png");
    this.load.image(
      "imgFish",
      "/assets/gameAssets/cookingGame/images/fish.png"
    );
    this.load.image(
      "imgCorn",
      "/assets/gameAssets/cookingGame/images/corn.png"
    );
    this.load.image(
      "imgBossBubble",
      "/assets/gameAssets/cookingGame/images/boss-bubble.png"
    );
    this.load.image(
      "imgCollection",
      "/assets/gameAssets/cookingGame/images/collection.png"
    );

    for (let index = 1; index <= 36; index++) {
      this.load.image(
        "imgCustomer" + index,
        "/assets/gameAssets/cookingGame/images/customers/c-" + index + ".png"
      );
    }

    //*** Load Sprite Sheets ***//

    // Common
    this.load.spritesheet(
      "sptIdea",
      "/assets/gameAssets/common/sprites/idea.png",
      {
        frameWidth: 120,
        frameHeight: 120,
      }
    );

    // Specific
    this.load.spritesheet(
      "sptDoor",
      "/assets/gameAssets/cookingGame/sprites/door-open-close.png",
      {
        frameWidth: 220,
        frameHeight: 150,
      }
    );

    this.load.spritesheet(
      "sptStovePan",
      "/assets/gameAssets/cookingGame/sprites/stove-pan.png",
      {
        frameWidth: 100,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptCrab",
      "/assets/gameAssets/cookingGame/sprites/crab.png",
      {
        frameWidth: 80,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptSteak",
      "/assets/gameAssets/cookingGame/sprites/steak.png",
      {
        frameWidth: 80,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptWing",
      "/assets/gameAssets/cookingGame/sprites/wing.png",
      {
        frameWidth: 80,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptStoveActions",
      "/assets/gameAssets/cookingGame/sprites/stove-actions.png",
      {
        frameWidth: 80,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptFireExting",
      "/assets/gameAssets/cookingGame/sprites/fire-exting.png",
      {
        frameWidth: 80,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptFireExtingActions",
      "/assets/gameAssets/cookingGame/sprites/fire-exting-action.png",
      {
        frameWidth: 160,
        frameHeight: 110,
      }
    );

    this.load.spritesheet(
      "sptHeart",
      "/assets/gameAssets/cookingGame/sprites/heart.png",
      {
        frameWidth: 80,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptMagic",
      "/assets/gameAssets/cookingGame/sprites/magic.png",
      {
        frameWidth: 480,
        frameHeight: 250,
      }
    );
    this.load.spritesheet(
      "sptMagicW",
      "/assets/gameAssets/cookingGame/sprites/magic-w.png",
      {
        frameWidth: 480,
        frameHeight: 250,
      }
    );

    this.load.spritesheet(
      "sptEbar",
      "/assets/gameAssets/cookingGame/sprites/ebar.png",
      {
        frameWidth: 60,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptCW",
      "/assets/gameAssets/cookingGame/sprites/c-w.png",
      {
        frameWidth: 50,
        frameHeight: 50,
      }
    );

    this.load.spritesheet(
      "sptBoss",
      "/assets/gameAssets/cookingGame/sprites/boss.png",
      {
        frameWidth: 320,
        frameHeight: 280,
      }
    );

    this.load.spritesheet(
      "sptShine",
      "/assets/gameAssets/cookingGame/sprites/shine.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptMagicBalls",
      "/assets/gameAssets/cookingGame/sprites/magic-balls.png",
      {
        frameWidth: 80,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptOrderBubble",
      "/assets/gameAssets/cookingGame/sprites/order-bubble.png",
      {
        frameWidth: 90,
        frameHeight: 80,
      }
    );

    this.load.spritesheet(
      "sptClock",
      "/assets/gameAssets/cookingGame/sprites/clock.png",
      {
        frameWidth: 25,
        frameHeight: 25,
      }
    );

    //*** load audio files ***//
    // general sfxs
    this.load.audio(
      "sfxCorrectAnswer",
      "/assets/gameAssets/common/sounds/answer-correct.wav"
    );
    this.load.audio(
      "sfxWrongAnswer",
      "/assets/gameAssets/common/sounds/answer-wrong.wav"
    );
    this.load.audio(
      "sfxScoreIncrease",
      "/assets/gameAssets/common/sounds/score-increase.wav"
    );
    this.load.audio(
      "sfxSimpleClick",
      "/assets/gameAssets/common/sounds/simple-click.wav"
    );
    this.load.audio(
      "sfxWarningAlarm",
      "/assets/gameAssets/common/sounds/warning-alarm.wav"
    );
    this.load.audio(
      "sfxFailure",
      "/assets/gameAssets/common/sounds/game-failure.wav"
    );
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("sfxClose", "/assets/gameAssets/common/sounds/close.wav");
    this.load.audio("sfxRound", "/assets/gameAssets/common/sounds/round.wav");
    this.load.audio("sfxGo", "/assets/gameAssets/common/sounds/go.wav");

    // sfx specific to the game
    this.load.audio("bgm", "/assets/gameAssets/cookingGame/sounds/bgm.mp3");
    this.load.audio(
      "sfxDoor",
      "/assets/gameAssets/cookingGame/sounds/door-open-close.wav"
    );
    this.load.audio(
      "sfxBubble",
      "/assets/gameAssets/cookingGame/sounds/bubble.wav"
    );
    this.load.audio(
      "sfxCookingBell",
      "/assets/gameAssets/cookingGame/sounds/cooking-bell.wav"
    );
    this.load.audio(
      "sfxMagic",
      "/assets/gameAssets/cookingGame/sounds/magic.wav"
    );
    this.load.audio(
      "sfxMagicW",
      "/assets/gameAssets/cookingGame/sounds/magic-w.wav"
    );
    this.load.audio(
      "sfxSlide",
      "/assets/gameAssets/cookingGame/sounds/slide.wav"
    );
    this.load.audio(
      "sfxShine",
      "/assets/gameAssets/cookingGame/sounds/shine.wav"
    );
    this.load.audio(
      "sfxBoss",
      "/assets/gameAssets/cookingGame/sounds/boss.wav"
    );
    this.load.audio(
      "sfxBossX",
      "/assets/gameAssets/cookingGame/sounds/bossx.wav"
    );
    this.load.audio(
      "sfxCorrect",
      "/assets/gameAssets/cookingGame/sounds/correct.wav"
    );
    this.load.audio(
      "sfxWrong",
      "/assets/gameAssets/cookingGame/sounds/wrong.wav"
    );
    this.load.audio(
      "sfxBossSuccess",
      "/assets/gameAssets/cookingGame/sounds/boss-success.wav"
    );
    this.load.audio(
      "sfxBossFail",
      "/assets/gameAssets/cookingGame/sounds/boss-fail.wav"
    );
    this.load.audio(
      "sfxCollect",
      "/assets/gameAssets/cookingGame/sounds/collect.wav"
    );
    this.load.audio(
      "sfxCharge",
      "/assets/gameAssets/cookingGame/sounds/charge.wav"
    );
    this.load.audio(
      "sfxBin",
      "/assets/gameAssets/cookingGame/sounds/bin.wav"
    );
    this.load.audio(
      "sfxExting",
      "/assets/gameAssets/cookingGame/sounds/extingz.mp3"
    );

    this.load.audio("sfxA", "/assets/gameAssets/cookingGame/sounds/a.wav");
    this.load.audio("sfxB", "/assets/gameAssets/cookingGame/sounds/b.wav");
    this.load.audio("sfxC", "/assets/gameAssets/cookingGame/sounds/c.wav");
    this.load.audio("sfxD", "/assets/gameAssets/cookingGame/sounds/d.wav");
    this.load.audio("sfxE", "/assets/gameAssets/cookingGame/sounds/e.wav");

    //*** Load Avatars ***//
    // load avatar data
    for (var key of Object.keys(this.avatar)) {
      if (this.avatar[key] && key != "color") {
        this.load.image(key, this.avatar[key]);
      }
    }

    // * For tutorial
    this.load.image("imgHamochiTalk", "/assets/gameAssets/common/tutorial/hamochi-talk.png");
    this.load.image("imgHamochiHappy", "/assets/gameAssets/common/tutorial/hamochi-happy.png");
    this.load.image("imgHamochiSad", "/assets/gameAssets/common/tutorial/hamochi-sad.png");
    this.load.image("imgArrow", "/assets/gameAssets/common/tutorial/arrow.png");
    this.load.spritesheet("sptClick", "/assets/gameAssets/common/tutorial/click.png", {
      frameWidth: 200,
      frameHeight: 200,
    });

    // load avatar masks
    this.load.image("maskAngry", "/assets/gameAssets/common/masks/M0021.png");
    this.load.image("maskNice", "/assets/gameAssets/common/masks/M0012.png");
    this.load.image("maskFear", "/assets/gameAssets/common/masks/M0040.png");
    this.load.image("maskSad", "/assets/gameAssets/common/masks/M0031.png");
    this.load.image("maskHappy", "/assets/gameAssets/common/masks/M0013.png");
    this.load.image("maskMad", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskStun", "/assets/gameAssets/common/masks/M0060.png");
    this.load.image("maskWeary", "/assets/gameAssets/common/masks/M0030.png");
    this.load.image("maskYummy", "/assets/gameAssets/common/masks/M0050.png");
    this.load.image("maskGrimace", "/assets/gameAssets/common/masks/M0070.png");
    this.load.image("maskScold", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskWearyx", "/assets/gameAssets/common/masks/M0020.png");
    this.load.image("maskHappyx", "/assets/gameAssets/common/masks/M0011.png");
  }

  create() {this.registry.destroy();
    this.events.off();
    this.scene.stop("LoadingScene");
    this.scene.start("PlayScene", {
      avatar: this.avatar,
      questions: this.questions,
      env: this.env,
      noOfRounds: this.noOfRounds,
      round: 1,
      friend: this.friend,
      score: 0,
      difficulty: 1,
      countCorrect: 0,
      avatarX: 220,
    });
  }
}
