import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import router from "@/router";
import _ from "lodash";
import Phaser from "phaser";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  selectedOption = -1;
  chats = [];
  currentChatId = 0;

  create() {
    this.chats_1 = [
      i18n.t("miniGames.foraging.tutorial.dialog_1"),
      i18n.t("miniGames.foraging.tutorial.dialog_2"),
      i18n.t("miniGames.foraging.tutorial.dialog_3"),
    ];

    this._backgroundImage = this.add
      .image(0, 0, "backgroundImage")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this._gameTitle = this.add.image(240, 320, "gameTitleImage").setScale(0.8);

    // if (
    //   store.state.settings.data.cache &&
    //   store.state.gameCache.data
    //   // && store.state.event.data.record.levelId == store.state.gameCache.data.level
    // ) {
    //   this._continueBtn = this.add
    //     .image(240, 450, "optionButton")
    //     .setScale(0.35);
    //   this._continueTxt = this.add.text(
    //     240,
    //     450,
    //     i18n.t("miniGames.general.resume"),
    //     {
    //       fontFamily: "Sassoon, sans-serif",
    //       fontSize: 20,
    //       color: "#000",
    //       align: "center",
    //     }
    //   );

    //   this._newBtn = this.add.image(240, 520, "optionButton").setScale(0.35);
    //   this._newTxt = this.add.text(
    //     240,
    //     520,
    //     i18n.t("miniGames.general.newGame"),
    //     {
    //       fontFamily: "Sassoon, sans-serif",
    //       fontSize: 20,
    //       color: "#000",
    //       align: "center",
    //     }
    //   );

    //   this.tweens.add({
    //     targets: this._continueBtn,
    //     scaleX: 0.38,
    //     scaleY: 0.38,
    //     ease: "Linear",
    //     duration: 500,
    //     yoyo: true,
    //     repeat: -1,
    //   });

    //   this.tweens.add({
    //     targets: this._continueTxt,
    //     scaleX: 1.1,
    //     scaleY: 1.1,
    //     ease: "Linear",
    //     duration: 500,
    //     yoyo: true,
    //     repeat: -1,
    //   });

      this.bgSound = this.sound.add("bg");
      this.bgSound.setVolume(
        store.state.settings.data.audio.music *
          store.state.settings.data.audio.master
      );
      this.bgSound.loop = true;

    //   this._continueTxt.on(
    //     "pointerdown",
    //     () => {
    //       this._backgroundImage.visible = false;
    //       this._gameTitle.visible = false;
    //       this._continueBtn.visible = false;
    //       this._continueTxt.visible = false;
    //       this._newBtn.visible = false;
    //       this._newTxt.visible = false;
    //       this.bgSound.play();

    //       if (store.state.gameCache.data.isBossCollected) {
    //         let _event_data = _.cloneDeep(store.state.event.data);
    //         _event_data.boss.isCollected = true;
    //         store.commit("updateEvent", _event_data);
    //       }

    //       this.scene.launch("RoundStartScene", {
    //         avatar: this.avatar,
    //         round: store.state.gameCache.data.round,
    //         questions: this.questions,
    //         friend: this.friend,
    //         score: store.state.gameCache.data.score,
    //         bossRound: store.state.gameCache.data.isBossOver
    //           ? -1
    //           : this.selectedOption == 2
    //           ? 1
    //           : this.bossRound,
    //         forceFriend: this.selectedOption == 1 ? true : false,
    //         noOfRounds: this.noOfRounds,
    //         difficulty: this.difficulty,
    //         countCorrect: store.state.gameCache.data.countCorrect,
    //       });
    //     },
    //     this
    //   );
    //   this._continueTxt.setOrigin(0.5);
    //   this._continueTxt.setPadding(10);
    //   this._continueTxt.setInteractive({ useHandCursor: true });
    //   this._continueTxt.setFixedSize(200, 40);

    //   this._newTxt.on(
    //     "pointerdown",
    //     () => {
    //       this._backgroundImage.visible = false;
    //       this._gameTitle.visible = false;
    //       this._continueBtn.visible = false;
    //       this._continueTxt.visible = false;
    //       this._newBtn.visible = false;
    //       this._newTxt.visible = false;
    //       this.bgSound.play();

    //       this.scene.launch("RoundStartScene", {
    //         avatar: this.avatar,
    //         round: 1,
    //         questions: this.questions,
    //         friend: this.friend,
    //         score: 0,
    //         bossRound: this.selectedOption == 2 ? 1 : this.bossRound,
    //         forceFriend: this.selectedOption == 1 ? true : false,
    //         noOfRounds: this.noOfRounds,
    //         difficulty: this.difficulty,
    //         countCorrect: 0,
    //       });
    //     },
    //     this
    //   );
    //   this._newTxt.setOrigin(0.5);
    //   this._newTxt.setPadding(10);
    //   this._newTxt.setInteractive({ useHandCursor: true });
    //   this._newTxt.setFixedSize(200, 40);
    // } else {
    this._continueBtn = this.add.image(240, 450, "optionButton").setScale(0.35);
    this._continueTxt = this.add.text(
      240,
      450,
      i18n.t("miniGames.general.continue"),
      {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
        align: "center",
      }
    );

    this.anims.create({
      key: "click_anim",
      frames: this.anims.generateFrameNumbers("click_sprite", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });

    // Tutorial
    this.tutorialBackground = this.add.rectangle(240, 427, 480, 854, 0x000000);
    this.tutorialBackground.setInteractive().on("pointerdown", () => {
      if (!this.onShake) {
        this.onShake = true;
        this.tweens.add({
          targets: this.tutorialChat,
          x: this.tutorialChat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
          onComplete: () => {
            this.onShake = false;
          },
        });
        this.tweens.add({
          targets: this.tutorialChat.chat,
          x: this.tutorialChat.chat.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.chat.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.text,
          x: this.tutorialChat.text.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.text.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.button,
          x: this.tutorialChat.button.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.button.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
        this.tweens.add({
          targets: this.tutorialChat.buttonText,
          x: this.tutorialChat.buttonText.x + Phaser.Math.Between(-5, 5),
          y: this.tutorialChat.buttonText.y + Phaser.Math.Between(-5, 5),
          duration: 50,
          repeat: 2,
          yoyo: true,
        });
      }
    });
    this.tutorialChat = this.add.graphics();
    this.tutorialChat.lineStyle(2, 0x00ff00);
    this.tutorialChat.fillStyle(0xffffff, 0.8);
    this.tutorialChat.fillRoundedRect(10, 680, 460, 154, 20);

    this.anims.create({
      key: "click_anim",
      frames: this.anims.generateFrameNumbers("click_sprite", {
        frames: [0, 1],
      }),
      frameRate: 5,
      repeat: -1,
    });
    this.tutorialClick = this.add
      .sprite(350, 500, "click_sprite")
      .setScale(0)
      .setDepth(10)
      .play("click_anim");

    const rect = new Phaser.Geom.Rectangle(10, 680, 460, 154);
    this.tutorialChat.setInteractive(rect, Phaser.Geom.Rectangle.Contains);
    this.tutorialChat.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialChat.chat = this.add
      .image(90, 755, "hamochi_talk")
      .setScale(0.25);

    this.tutorialChat.text = this.add.text(170, 740, "", {
      fontSize: "20px",
      wordWrap: { width: 300 },
      fontFamily: "Sassoon, sans-serif",
      color: "#000000",
      align: "left",
    });

    this.tutorialChat.text.setOrigin(0, 0.5);

    this.tutorialChat.button = this.add
      .graphics()
      .fillStyle(0xc7c7c7, 1)
      .fillRoundedRect(334, 802, 124, 20, 10);

    this.tutorialChat.buttonText = this.add.text(
      340,
      805,
      "▼" + i18n.t("action.clickToContinue"),
      {
        fontSize: "12px",
        fill: "#000",
        fontFamily: "Sassoon, sans-serif",
      }
    );

    this.tutorialChat.buttonText.setInteractive({ useHandCursor: true });

    this.tutorialChat.buttonText.on("pointerdown", () => {
      this.continueChat();
    });

    this.tutorialBackground.setDepth(9);
    this.tutorialChat.setDepth(9);
    this.tutorialChat.chat.setDepth(9);
    this.tutorialChat.text.setDepth(9);
    this.tutorialChat.button.setDepth(9);
    this.tutorialChat.buttonText.setDepth(9);

    this.tutorialBackground.setAlpha(0);
    this.tutorialChat.setAlpha(0);
    this.tutorialChat.chat.setAlpha(0);
    this.tutorialChat.text.setAlpha(0);
    this.tutorialChat.button.setAlpha(0);
    this.tutorialChat.buttonText.setAlpha(0);

    this.tweens.add({
      targets: this._continueBtn,
      scaleX: 0.38,
      scaleY: 0.38,
      ease: "Linear",
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    this.tweens.add({
      targets: this._continueTxt,
      scaleX: 1.1,
      scaleY: 1.1,
      ease: "Linear",
      duration: 500,
      yoyo: true,
      repeat: -1,
    });

    this.bgSound = this.sound.add("bg");
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
    this.bgSound.loop = true;

    this.currentChatId = 0;
    this.chats = this.chats_1;
    this.tutorialChat.text.setText(this.chats[this.currentChatId]);
    this.startTutorial();

    // if (store.state.settings.data.debug) {
    //   this.debug_bg = this.add
    //     .graphics()
    //     .fillStyle(0x000000, 1)
    //     .fillRect(130, 492, 220, 30);
    //   this.debug_dot = this.add.circle(160, 508, 5, 0xff0000);
    //   this.debug_txt = this.add.text(180, 500, "DEBUG OPTIONS", {
    //     fontFamily: "Sassoon, sans-serif",
    //     fontSize: 15,
    //     color: "#fff",
    //   });
    //   this.radioGroup = this.add.group();
    //   this.selectedOption = 0;
    //   let options = [
    //     "Normal round",
    //     "Normal round with friend",
    //     "Boss round",
    //     "Auto Play",
    //   ];

    //   for (let i = 0; i < 4; i++) {
    //     let radioButton = this.add.circle(150, 550 + i * 40, 10, 0xffffff);
    //     if (i == this.selectedOption) {
    //       radioButton.setFillStyle(0xfce303);
    //     }
    //     radioButton.setInteractive();
    //     radioButton.on("pointerdown", () => {
    //       this.radioGroup.children.iterate(function (radio) {
    //         radio.setFillStyle(0xffffff);
    //       });
    //       radioButton.setFillStyle(0xfce303);
    //       this.selectedOption = i;
    //     });
    //     radioButton.lableTxt = this.add.text(170, 540 + i * 40, options[i], {
    //       fontFamily: "Sassoon, sans-serif",
    //       fontSize: 15,
    //       color: "#000",
    //     });
    //     this.radioGroup.add(radioButton);
    //   }
    // }

    this._continueTxt.on(
      "pointerdown",
      () => {
        if (this.selectedOption == 3) {
          let score = Math.floor(Math.random() * (1000 - 900 + 1)) + 900;
          this.event_data = _.cloneDeep(store.state.event.data);
          this.event_data.submission.countCorrect =
            Math.floor(Math.random() * (10 - 7 + 1)) + 7;
          this.event_data.submission.accuracy = score / 10;
          this.event_data.submission.star = 3;
          this.event_data.submission.score = score;
          this.event_data.submission.submission += 1;
          this.event_data.boss.isCollected = true;
          this.event_data.record.attempt += 1;
          this.event_data.record.submission += 1;
          this.event_data.record.topScoreOld = this.event_data.record.topScore;
          this.event_data.record.topStarOld = this.event_data.record.topStar;
          if (score > this.event_data.record.topScore) {
            this.event_data.record.topScore = score;
            this.event_data.record.topStar = 3;
          }

          this.event_data.gameLoot = Math.floor(
            Math.random() * (5 - 3 + 1) + 3
          );
          store.commit("updateEvent", this.event_data);

          this.time.delayedCall(100, () => {
            router.push({
              name: "PageEventGameScore",
              params: {
                id: store.state.event.data.record.levelId,
              },
            });
          });
        } else {
          this._backgroundImage.visible = false;
          this._gameTitle.visible = false;
          this._continueBtn.visible = false;
          this._continueTxt.visible = false;
          this.tutorialClick.visible = false;

          // if (store.state.settings.data.debug) {
          //   this.radioGroup.children.entries.forEach((element) => {
          //     element.visible = false;
          //     element.lableTxt.visible = false;
          //   });
          //   this.debug_bg.visible = false;
          //   this.debug_txt.visible = false;
          //   this.debug_dot.visible = false;
          // }
          this.bgSound.play();

          this.scene.launch("PlayScene", {
            avatar: this.avatar,
            round: 1,
            questions: this.questions,
            friend: this.friend,
            score: 0,
            bossRound: this.selectedOption == 2 ? 1 : this.bossRound,
            forceFriend: this.selectedOption == 1 ? true : false,
            noOfRounds: this.noOfRounds,
            difficulty: this.difficulty,
            countCorrect: 0,
            avatarX: 220,
          });
        }
      },
      this
    );
    this._continueTxt.setOrigin(0.5).setPadding(10).setFixedSize(200, 40);
  }
  // }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }

  startTutorial() {
    this.tweens.add({
      targets: this.tutorialBackground,
      alpha: 0.7,
      duration: 200,
      ease: "Sine.easeOut",
      delay: 500,
      onComplete: () => {
        this.tweens.add({
          targets: this.tutorialChat,
          alpha: 1,
          duration: 100,
          ease: "Sine.easeOut",
          delay: 500,
          onComplete: () => {
            this.tweens.add({
              targets: this.tutorialChat.chat,
              alpha: 1,
              duration: 500,
              ease: "Sine.easeOut",
              onComplete: () => {
                this.tweens.add({
                  targets: [
                    this.tutorialChat.text,
                    this.tutorialChat.button,
                    this.tutorialChat.buttonText,
                  ],
                  alpha: 1,
                  duration: 500,
                  ease: "Sine.easeOut",
                });
              },
            });
          },
        });
      },
    });
  }

  continueChat() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.currentChatId++;
    if (this.chats[this.currentChatId]) {
      this.tweens.add({
        targets: this.tutorialChat.text,
        alpha: 0,
        duration: 100,
        ease: "Sine.easeOut",
        onComplete: () => {
          this.tutorialChat.text.setText(this.chats[this.currentChatId]);
          this.tweens.add({
            targets: this.tutorialChat.text,
            alpha: 1,
            duration: 100,
            ease: "Sine.easeOut",
          });
        },
      });
    } else {
      this.tweens.add({
        targets: [
          this.tutorialChat,
          this.tutorialChat.chat,
          this.tutorialChat.text,
          this.tutorialChat.button,
          this.tutorialChat.buttonText,
          this.tutorialBackground,
        ],
        alpha: 0,
        duration: 500,
        ease: "Sine.easeOut",
      });
      this.time.delayedCall(200, () => {
        this._continueTxt.setInteractive({ useHandCursor: true });
        this.tutorialClick.setScale(0.5);
      });
    }
  }
}
